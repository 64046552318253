import service, { ApiResponse, getToken } from '@/axios/service';
import { getPublicKey } from './user';
import { encodeStr } from '@/utils/encrypt';

export interface TeamAccountResponse {
  list: TeamAccount[];
  total: number;
}

export interface TeamAccount {
  create_time: number;
  email: string;
  id: number;
  mobile: string;
  nickname: string;
}

export interface FetchAccountsRequest {
  page_no: number;
  page_size: number;
  nickname: string;
  start_time: string;
  end_time: string;
}

export interface CreateAccountRequest {
  mobile: string;
  username: string;
  email: string;
  nickname: string;
  password: string;
  team_id: number;
  uuid: string;
}

export interface EditAccountRequest {
  id: string;
  mobile: string;
  email: string;
  nickname: string;
}

export interface ResetPasswordRequest {
  id: string;
  old_password: string;
  new_password: string;
  uuid: string;
}

export const fetchTeamAccounts = (request: FetchAccountsRequest): Promise<ApiResponse<TeamAccountResponse>> => {
  return service.get<ApiResponse<TeamAccountResponse>>(`/apis/app.aivt.digitalnotee.api/team-accounts/v1alpha1`, {
    params: request
  });
};

export const addTeamAccount = async (request: CreateAccountRequest): Promise<ApiResponse<string>> => {
  const uuid = await getPublicKey() as string;
  return service.post<ApiResponse<string>>(`/apis/app.aivt.digitalnotee.api/team-accounts/v1alpha1`, {
    ...request,
    password: encodeStr(request.password),
    uuid
  });
};

export const editTeamAccount = (request: EditAccountRequest): Promise<ApiResponse<string>> => {
  return service.patch<ApiResponse<string>>(`/apis/app.aivt.digitalnotee.api/team-accounts/v1alpha1/${request.id}`, request);
};

export const deleteTeamAccount = (id: string): Promise<ApiResponse<any>> => {
  return service.delete<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/team-accounts/v1alpha1/${id}`);
};

export const resetPassword = async (request: ResetPasswordRequest): Promise<ApiResponse<any>> => {
  const uuid = await getPublicKey() as string;
  request.old_password = encodeStr(request.old_password);
  request.new_password = encodeStr(request.new_password);
  request.uuid = uuid;
  return service.patch<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/team-accounts/v1alpha1/${request.id}/reset_password`, request);
};
