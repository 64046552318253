
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './style.module.css';
import { useWavesurfer } from '@wavesurfer/react'
import { projectData } from '@/stores/data/project-data';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions';
import directingEngine from '@/stores/directing-engine/direct-engine';
import { directData } from '@/stores/data/direct-data';
import { observer } from 'mobx-react-lite';
import currSubtitle from '@/stores/data/curr-subtitle';

const topTimeline = TimelinePlugin.create({
  height: 30,
  insertPosition: 'beforebegin',
  timeInterval: 1,
  primaryLabelInterval: 5,
  secondaryLabelInterval: 5,
  style: {
    fontSize: '10px',
    color: '#2D5B88',
  },
});

const wsRegions = RegionsPlugin.create();

const Track: React.FC<any> = observer(() => {
  const containerRef = useRef(null)
  const [lastMeta, setLastMeta] = useState("")
  
  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    height: 80,
    waveColor: '#6C6ACF',
    progressColor: '#a9a9a9',
    barWidth: 2,
    barGap: 1,
    barRadius: 2,
    minPxPerSec: 10,
    autoScroll: true,
    hideScrollbar: false,
    autoCenter: true,
    fillParent: true,
    url: projectData.audio_obj_link,
    plugins: useMemo(() => [topTimeline, wsRegions], []),
    // plugins: useMemo(() => [Timeline.create()], []),
  })

  const style = document.createElement('style');
  style.textContent = `
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #1F1F26;
  }
  
  ::-webkit-scrollbar-corner {
    background-color: #1F1F26;
  }
  
  ::-webkit-scrollbar-thumb{
    border: 1px solid #1F1F26;
    background-color: rgb(121, 118, 118,.3);
    border-radius: 42px;
    visibility: visible;
  }`;
  wavesurfer?.getWrapper().appendChild(style);

  wavesurfer?.setVolume(0);

  useEffect(() => {
    if (!directingEngine.isPlaying) {
      isPlaying && wavesurfer?.pause();
      seekIndicator(directData.getCurrent());
    } else {
      if (!isPlaying) {
        wavesurfer?.play();
      }
    }

    if (lastMeta != JSON.stringify([currSubtitle.id, currSubtitle.startAt, currSubtitle.endAt])) {
      wsRegions.clearRegions();
      tryShowRegion();
      setLastMeta(JSON.stringify([currSubtitle.id, currSubtitle.startAt, currSubtitle.endAt]));
    }
  }, [directData.current, currSubtitle.id, currSubtitle.endAt, directingEngine.isPlaying])

  const seekIndicator = useCallback((seconds: number) => {
    wavesurfer && wavesurfer.setTime(seconds)
  }, [wavesurfer])

  const tryShowRegion = () => {
    if (currSubtitle.id != "") {
      wsRegions.addRegion({
        start: currSubtitle.startAt,
        end: currSubtitle.endAt,
        color: "rgba(169, 157, 169, 0.3)",
        drag: false,
        resize: false,
      });
    }
  }

  wavesurfer?.on('redrawcomplete', () => {
    projectData.resoucesReady();
  })

  wavesurfer?.on('decode', () => {
    
    // wsRegions.addRegion({
    //   start: 4,
    //   end: 7,
    //   content: 'Blue',
    // })
  })

  wavesurfer?.once('interaction', (time: number) => {
    directData.setCurrent(time);
  })

  return (
    <div className={s.container} onClick={e => e.preventDefault()}>
        <div className={s.segmentTackers}> 
          <div ref={containerRef} />
        </div>
    </div>
  );
});

export default Track;