export class DispatchInstance {
    private readonly _name: string;
    private readonly _order: number;
    private readonly _handlerName: string;

    constructor(name: string, order: number, handlerName: string) {
        this._name = name;
        this._order = order;
        this._handlerName = handlerName;
    }

    get name(): string {
        return this._name;
    }

    get order(): number {
        return this._order;
    }

    get handlerName(): string {
        return this._handlerName;
    }
}