import { useEffect, useState } from 'react';
import s from './style.module.css';
import { Button, Col, Input, Row, message } from 'antd';
import { projectData } from '@/stores/data/project-data';
import { SensitiveWordData, getSensitiveWords } from '@/axios/project';
import Table, { ColumnsType } from 'antd/lib/table';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import cn from "classnames"
import { useDebounceFn } from 'ahooks';
import { observer } from 'mobx-react-lite';

const SensitiveWords: React.FC<any> = observer((props: { replace: Function }) => {
  const [words, setWords] = useState<any[]>([]);

  useEffect(() => {
    setWords(projectData.sensitive_word_settings || []);
  }, [])

  const { run: debounceSave } = useDebounceFn(
    () => {
      projectData.sensitive_word_settings = words.map(word => {
        return {
          sensitive_word: word.sensitive_word,
          replace: word.replace
        } as SensitiveWordData;
      });
      projectData.save();
      message.success("更新成功!");
    },
    {
      wait: 1000,
    },
  );

  const handleAddWord = (e: any) => {
    console.log(words);
    setWords([...words, {sensitive_word: "", replace: ""}]);
    message.success("添加成功!");
    debounceSave();
  };

  const handleKeywordsChange = (e: any, index: number) => {
    const newWords = words.slice(0);
    newWords[index].sensitive_word = e.target.value;
    setWords(newWords);
    debounceSave();
  }

  const handleReplaceChange = (e: any, index: number) => {
    const newWords = words.slice(0);
    newWords[index].replace = e.target.value;
    setWords(newWords);
    debounceSave();
  }

  const handleApply = () => {
    projectData.applySensitiveWords(words);
    message.success("应用成功!");
  }

  const handleRemove = (index: number) => {
    const newWords = words.slice(0);
    newWords.splice(index, 1);
    setWords(newWords);
    debounceSave();
  }

  const columns: ColumnsType<any> = [
    {
      title: '敏感词',
      render: (_, record, index) => (
        <Input className={s.input} value={record.sensitive_word} maxLength={30} placeholder={"请输入敏感词"} onChange={(e: any) => handleKeywordsChange(e, index)}></Input>
      ),
    },
    {
      title: '替换为',
      render: (_, record, index) => (
        <Input className={s.input} value={record.replace} maxLength={30} placeholder={"请输入替换文本"} onChange={(e: any) => handleReplaceChange(e, index)}></Input>
      ),
    },
    {
      title: '操作',
      dataIndex: '',
      render: (_, record, index) => (
        <a onClick={e => handleRemove(index)}><MinusCircleOutlined /></a>
      ),
    },
  ];

  return (
    <div className={s.container}>
      <div className={s.row}>
        <Table
          className={s.table}
          columns={columns}
          dataSource={words}
          pagination={false}
        />
      </div>
      <div className={cn(s.addIcon, s.iconWrap)} onClick={handleAddWord}>
        <PlusOutlined className={s.icon} />
      </div>
      <div className={s.right}>
        <Button className={s.actionBtn} onClick={handleApply}>
          应用
        </Button>
      </div>
    </div>
  );
});

export default SensitiveWords;