export default class HandlerInstance {
    private readonly _name: string;
    private readonly _func: Function;

    constructor(name: string, func: Function) {
        this._name = name;
        this._func = func;
    }

    get name(): string {
        return this._name;
    }

    get func(): Function {
        return this._func;
    }
}