import user from '@/stores/user';
import axios from 'axios';
import service, { ApiResponse, TENANT_ID } from './service';
import { encodeStr } from '@/utils/encrypt';

interface LoginRequest {
  account_name: string;
  password: string;
  uuid: string;
}

interface LoginResponse {
  account_id: number;
  team_id: number;
  access_token: string;
  refresh_token: string;  
  expired_at: number;
}

interface PublicKeyResponse {
  uuid: string;
  publicKey: string;
}

export const userLogin = async (accountName: string, password: string): Promise<boolean> => {
  const uuid = await getPublicKey() as string;

  const account: LoginRequest = {
    account_name: accountName,
    password: encodeStr(password),
    uuid,
  };

  return axios.post('/apis/app.aivt.digitalnotee.api/team-accounts/v1alpha1/authenticate', account, {
    withCredentials: true,
    headers: {
      'Tenant-Id': TENANT_ID,
    }
  }).then(async (res) => {
    const { code, data, msg } = res.data as ApiResponse<LoginResponse>;
    if (code == 0) {
      user.setInfo({ id: data.account_id.toString(), token: data.access_token, teamId: data.team_id, refreshToken: data.refresh_token });
      localStorage.setItem('user-token', data.access_token);
      localStorage.setItem('user-refresh-token', data.refresh_token);
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }    
  }).catch((error) => {
    console.log(error);
    return Promise.resolve(false);
  });
};

export const userLogout = async (): Promise<boolean> => {
  return service.delete('/apis/app.aivt.digitalnotee.api/team-accounts/v1alpha1/logout').then(async (res) => {
    const { code, data, msg } = res.data as ApiResponse<LoginResponse>;
    if (code == 0) {
      user.setInfo(null);
      localStorage.setItem('user-token', "");
      localStorage.setItem('user-refresh-token', "");
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }    
  }).catch((error) => {
    console.log(error);
    return Promise.resolve(false);
  });
};

export function getTokenByRefreshToken(refreshToken: string) {
  return service<ApiResponse<LoginResponse>>({
    method: 'PATCH',
    url: `/apis/app.aivt.digitalnotee.api/team-accounts/v1alpha1/authenticate/renew/${refreshToken}`,
  });
}

export function getPublicKey() {
  return axios.post('/admin-api/system/auth/getPubicKey', {}, {
    withCredentials: true,
    headers: {
      'Tenant-Id': TENANT_ID,
    }
  }).then(async (res) => {
    const { code, data, msg } = res.data as ApiResponse<PublicKeyResponse>;
    if (code == 0) {
      localStorage.setItem('public-key', data.publicKey);
      return Promise.resolve(data.uuid);
    } else {
      return Promise.resolve(undefined);
    }
  }).catch((error) => {
    console.log(error);
    return Promise.resolve(undefined);
  });
}

export function getInfo() {
  return service.get<ApiResponse<any>>("/apis/app.aivt.digitalnotee.api/team-accounts/v1alpha1/profile");
}
