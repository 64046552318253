export const calcTargetLanguageReadingSpeed = (translatedText: string, duration: number): number => {
    const SPEEDEN = 700 / 60000; // 英文读速 700 字符/分钟
    const speed = translatedText.length / duration;
    const ratial = speed / SPEEDEN;
    if (ratial < 0.5) {
        return 0.5;
    }
    if (ratial > 2) {
        return 2;
    }
    return Number(ratial.toFixed(1));
}
