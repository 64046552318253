import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'
import { ConfigProvider, message } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'antd/dist/antd.dark.css';
import "react-contexify/dist/ReactContexify.css";
import 'moment/locale/zh-cn';
import moment from 'moment';

import './styles/variables.css'
import './styles/index.css';
import RootRouter from './routes/RootRouter';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);
moment.locale('zh-cn');

root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <ConfigProvider locale={zhCN}>
        {/* <Provider {...stores}> */}
        <RootRouter />
        {/* </Provider> */}
      </ConfigProvider>
    </DndProvider>
  </React.StrictMode>
);
