import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import s from './style.module.css';
import { useState } from 'react';
import { Progress, Upload, UploadFile, UploadProps, message } from 'antd';
import directingEngine from '@/stores/directing-engine/direct-engine';
import path from "path"
import ConfirmModal from '../confirm-modal';
import { uploadFile } from '@/storage';
import { RESOURCE_TYPE } from '@/axios/storage';
import { createProject } from '@/axios/project';
import { round } from '@/nos/utils';
import { createProjectData } from '@/stores/data/create-project-data';
import { getQuota } from '@/axios/recharge';

const MAX_SIZE = 1 * 1024 * 1024 * 1024;
const MAX_DURATION = 60 * 60;
const VIDEO_SUFFIX = [".mp4", ".mov", ".mkv"];
const SUBTITLE_SUFFIX = [".xls", ".xlsx"];

const ImportModal: React.FC<any> = (props: { open: boolean, onSubmit: Function, onCancel: Function }) => {
  const { Dragger } = Upload;
  
  const [showConfirm, setShowConfirm] = useState(false);

  const [isVUploading, setIsVUploading] = useState(false);
  const [isAUploading, setIsAUploading] = useState(false);
  const [vPercent, setVPercent] = useState(0);
  const [aPercent, setAPercent] = useState(0);

  const [subtitleFile, setSubtitleFile] = useState("");
  const [subtitleUrl, setSubtitleUrl] = useState("");
  const [isSubUploading, setIsSubUploading] = useState(false);

  const [audioEle, setAudioEle] = useState<HTMLAudioElement>();

  const getVideoDuration = (file: File): Promise<any> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      const resolveError = (e: any) => {
        console.log(e);
        message.error("加载视频文件出错，请重试！");
        reset();
      }

      reader.readAsArrayBuffer(file);
      reader.onload = function(e: any) {
        console.log("init")
        const buffer = e.target.result;
        const videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
        const url = window.URL.createObjectURL(videoBlob);
        const media = new Audio();
        media.src = url;
        setAudioEle(media);
        
        media.onloadedmetadata = () => resolve(media.duration);
        media.onerror = resolveError;
      }
      reader.onerror = resolveError;
  });
  
  const handleUpload = async (options: any) => {
    setVPercent(0);
    setAPercent(0);
    setIsVUploading(true);
    setIsAUploading(true);
    const { file } = options;
    const videoFile = file as File;

    console.log(videoFile)
    const duration = await getVideoDuration(videoFile);
    URL.revokeObjectURL(audioEle?.baseURI as string);
    audioEle?.remove();

    console.log(duration)
    if (duration > MAX_DURATION) {
      reset();
      message.error(`超过最大时长60分钟，请调整后重新上传！`);
      return;
    }
    
    const callbacks = {
      onProgress: (e: any) => {
        setVPercent(e.percent * 0.6);
      },
      onSuccess: () => {
        setIsVUploading(false);
        createProjectData.name = videoFile.name;
        createProjectData.duration = duration;
        createProjectData.videoUrl = videoUrl;
      },
      onError: (e:any) => console.log(e)
    } as any;
    
    const videoUrl = await uploadFile(videoFile, RESOURCE_TYPE.RAW_VIDEO_UPLOAD, callbacks);
    
    const audioCallbacks = {
      onProgress: (e: any) => {
        setAPercent(e.percent * 0.4);
      },
      onSuccess: () => {
        setIsAUploading(false);
        createProjectData.audioUrl = audioUrl;
      },
      onError: (e:any) => console.log(e)
    } as any;
    const audioFile = await directingEngine.VideoSeparation(videoFile.name, videoFile);
    const audioUrl = await uploadFile(audioFile, RESOURCE_TYPE.RAW_AUDIO_UPLOAD, audioCallbacks);

  };

  const handleSubUpload = async (options: any) => {
    setIsSubUploading(true);
    const { file } = options;
    const filename = (file as File).name;
    setSubtitleFile(path.basename(filename, path.extname(filename)));
    const callbacks = {
      onProgress: (e: any) => console.log(e),
      onSuccess: (e: any) => {
        setIsSubUploading(false);
        createProjectData.subtitleUrl = subtitleUrl;
        console.log(e);
      }, 
      onError: (e: any) => console.log(e) } as any;
    const subtitleUrl = await uploadFile(file, RESOURCE_TYPE.RAW_TEXT_UPLOAD, callbacks);
    setSubtitleUrl(subtitleUrl);
  };

  const uploadProps: UploadProps = {
    customRequest: handleUpload,
    beforeUpload: async (file: UploadFile) => {
      const suffix = path.extname(file.name);
      const isAllowedType = VIDEO_SUFFIX.includes(suffix);
      if (!isAllowedType) {
        message.error(`${suffix}不是支持的视频格式，请调整后重新上传！`);
        return false;
      }
      if (file.size && file.size > MAX_SIZE) {
        message.error(`文件大小超过1G，请调整后重新上传！`);
        return false;
      }
      return true;
    },
    onDrop: (event) => {
      console.log(event);
    },
    showUploadList: false,
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        console.log(file, fileList);
      }
      if (file.status === 'done') {
        console.log(`${file.name} file uploaded successfully`);
      } else if (file.status === 'error') {
        console.log(`${file.name} file upload failed.`);
      }
    },
    defaultFileList: [],
  };

  const uploadSubProps: UploadProps = {
    customRequest: handleSubUpload,
    beforeUpload: (file: UploadFile) => {
      const suffix = path.extname(file.name);
      const isAllowedType = SUBTITLE_SUFFIX.includes(suffix);
      if (!isAllowedType) {
        message.error(`${suffix}不是支持的字幕对照格式，请调整后重新上传！`);
        return false;
      }
      return true;
    },
    showUploadList: false,
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        console.log(file, fileList);
      }
      if (file.status === 'done') {
        console.log(`${file.name} file uploaded successfully`);
      } else if (file.status === 'error') {
        console.log(`${file.name} file upload failed.`);
      }

    },
    defaultFileList: [],
  };

  const handleSubmit = async () => {
    if (createProjectData.videoUrl == "") {
      message.warning("请先上传视频，再提交项目。");
      return;
    }

    const res = await getQuota();
    if (res.code == 0) {
      if (res.data.remain_available_seconds - createProjectData.duration < 0) {
        message.warning("账户所剩时长不足，请充值后再试！");
        return;
      }
    }
    else {
      message.warning("服务异常，请稍后重试！");
      return;
    }
    setShowConfirm(true)
  }

  const handleConfirmSubmit = async () => {
    const ret = await createProject(createProjectData.toRequest());
    message.success(`创建项目成功，编号${ret.data}。`);
    reset();
    props.onSubmit()
  }
  
  const handleConfirmCancel = () => setShowConfirm(false);

  const handleCancel = () => {
    reset();
    props.onCancel();
  }

  const reset = () => {
    createProjectData.videoUrl = ""; 
    setShowConfirm(false); 
    setVPercent(0);
    setAPercent(0);
    setIsVUploading(false);
    setIsAUploading(false);
    setSubtitleFile("");
    setSubtitleUrl("");
    createProjectData.init();
  };

  return (
    <>
    <Modal
      className={s.modal}
      open={props.open}
      title="视频翻译"
      centered
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <div className={s.modalBody}>
        <div className={s.left}>
          <Dragger className={s.video} {...uploadProps}>
            {
              isAUploading || isVUploading || createProjectData.videoUrl != "" ? <Progress type="circle" percent={round(vPercent + aPercent, 2)} /> : <div className={s.plusImage} />
            }
          </Dragger>
          <div className={s.indicator}>
            支持MP4格式，视频≤1GB，时长≤60
          </div>
        </div>
        <div className={s.right}>
          <div className={s.uploadLine}>
            <Upload {...uploadSubProps}>
              <Button className={s.uploadBtn} type="primary" onClick={() => { }}>
                { subtitleFile ? "重新上传" : "上传台词" }
              </Button>
            </Upload>
            {
              subtitleFile && <Button className={s.deleteBtn} onClick={e => setSubtitleFile("")}>
                删除
              </Button>
            }
          </div>
          {
            subtitleFile && <div className={s.subtitle}>
              {subtitleFile}
            </div>
          }
          <div className={s.uploadLine}>
            <div className={s.title}>
              上传原视频台词文件（.xlsx, .xls）
            </div>
          </div>
          <div className={s.uploadLine}>
            <div>
              <div>源语言：</div>
              <div>目标语言：</div>
            </div>
            <div className={s.selectGroup}>
              <div>简体中文</div>
              <div>英语</div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.modalFooter}>
        <Button className={s.cancelBtn} onClick={handleCancel}>
          取消
        </Button>
        <Button className={s.submitBtn} type="primary" loading={isVUploading || isAUploading || isSubUploading} onClick={handleSubmit}>
          确认
        </Button>
      </div>
    </Modal>
    <ConfirmModal show={showConfirm} onSubmit={handleConfirmSubmit} onCancel={handleConfirmCancel}></ConfirmModal>
    </>
  );
};

export default ImportModal;