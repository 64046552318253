export default function request (url: string, options: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open(options.method, url)
  
      if (options.onCreate) {
        options.onCreate(xhr)
      }
      if (options.headers) {
        Object.keys(options.headers).forEach(k =>
          xhr.setRequestHeader(k, options.headers[k])
        )
      }
  
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable && options.onProgress) {
          options.onProgress({ loaded: evt.loaded, total: evt.total })
        }
      })
  
      xhr.onreadystatechange = () => {
        const responseText = xhr.responseText
        if (xhr.readyState !== 4) {
          return
        }
        if (xhr.status !== 200) {
          let message = `xhr request failed, code: ${xhr.status};`
          if (responseText) {
            message = message + ` response: ${responseText}`
          }
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ code: xhr.status, message })
          return
        }
        resolve({ data: JSON.parse(responseText) })
      }
  
      xhr.send(options.body)
    })
  }
