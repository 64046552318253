import React, { useState } from 'react';
import { useHistory } from 'react-router';
import s from './style.module.css';
import { Button, Input, message } from 'antd';
import { URL } from '@/routes'
import { userLogin } from '@/axios/user';

const LoginPage: React.FC = () => {
  const history = useHistory();
  const [accountName, setAccountName] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = async () => {
    if (await userLogin(accountName, password)) {
      history.push(URL.workbench);
    } else {
      message.error('账号或密码错误，请重新输入后再试。');
    }
  };

  return (
    <div className={s.container}>
      <div className={s.logo} />
      <div className={s.loginPanel}>
        <div className={s.title}>METai 视听译制平台</div>
        <div className={s.subTitle}>欢迎使用</div>
        <Input className={s.input} value={accountName} onChange={e => setAccountName(e.target.value)} placeholder="账号" prefix={<span className={s.phoneIcon} />} />
        <Input.Password className={s.input} value={password} onChange={e => setPassword(e.target.value)} placeholder="密码" prefix={<span className={s.passIcon} />} />
        <Button className={s.button} onClick={onLogin}>
          登录
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
                                
