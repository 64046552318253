/**
 * Scope 定义了日志范围
 */
export enum Scope {
  Global = 'GLOBAL',
  DirectEngine = 'DIRECT_ENGINE',
  StageEngine = 'STAGE_ENGINE',
  MaterialManager = 'MATERIAL_MANAGER',
  UI = 'UI',
  UndoRedoManager = 'UNDO/REDO',
  Script = 'SCRIPT',
  Track = 'TRACK',
  Segment = 'SEGMENT',
  Mediator = 'MEDIATOR',
}

export type Level = 'info' | 'warn' | 'error' | 'fatal' | 'debug';

export class Logger {
  private readonly scope: Scope;

  constructor(scope: Scope) {
    this.scope = scope;
  }

  private log(level: Level, msg: string, ...args: any[]) {
    if (process.env.NODE_ENV === 'production') {
      return;
    }

    const now = new Date().toLocaleString();
    msg = `${now} - ${level.toUpperCase()} - [${this.scope}] ` + msg;
    (args[0] as Array<any>).forEach((arg) => {
      switch (typeof arg) {
        case 'object':
          msg = msg.replace(`{}`, JSON.stringify(arg)); // FIXME: 这里打印某些结构体会报错
          break;
        case 'function':
          msg = msg.replace(`{}`, (arg as Function).name);
          break;
        default:
          msg = msg.replace(`{}`, arg.toString());
      }
    });
    console.log(msg);
  }

  public info(msg: string, ...args: any[]): void {
    return this.log('info', msg, args);
  }

  public warn(msg: string, ...args: any[]): void {
    return this.log('warn', msg, args);
  }

  public error(msg: string, ...args: any[]): void {
    return this.log('error', msg, args);
  }

  public debug(msg: string, ...args: any[]): void {
    return this.log('debug', msg, args);
  }
}

const loggers = new Map<Scope, Logger>();
const globalLogger = new Logger(Scope.Global);
loggers.set(Scope.Global, globalLogger);

/**
 * 根据范围获取对应的 Logger，如果不存在会创建
 * @param scope 日志范围，默认为 Scope.Global
 * @see Scope
 */
export const getLogger = (scope?: Scope): Logger => {
  if (!scope) {
    return globalLogger;
  }

  let logger = loggers.get(scope);
  if (!logger) {
    logger = new Logger(scope);
    loggers.set(scope, logger);
  }
  return logger;
};
