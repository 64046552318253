import {
  genAllAudio,
  getAvailSpeakers,
  getGenStatus,
  getLatestSnapshot,
  previewAudio,
  translate,
} from '@/axios/project';
import { RESOURCE_TYPE } from '@/axios/storage';
import { uploadFile } from '@/storage';
import { downloadFile } from '@/utils/file';
import { id } from '@/utils/id';
import { calcTargetLanguageReadingSpeed } from '@/utils/speakSpeed';
import { toSeconds, toSrtTimeString } from '@/utils/time';
import { message } from 'antd';
import currSubtitle from '../data/curr-subtitle';
import { directData } from '../data/direct-data';
import { projectData } from '../data/project-data';
import directingEngine from '../directing-engine/direct-engine';
import { Material, MaterialType, materialManager } from '../material-manager/material-manager';
import NotificationHandler, { INotificationHandler } from '../mediator-system/notification';
import { SubtitleData } from '../model/project';
import {
  DeleteSubtitleNotification,
  DownloadAudioNotification,
  InsertSubtitleNotification,
  MergeSubtitleNotification,
  PreviewSubtitleNotification,
  ProjectInitNotification,
  ReplaceTextNotification,
  SplitSubtitleNotification,
  TranslateSubtitleNotification,
} from '../notification/project';

export const Events = {
  PROJECT_INIT: 'ProjectInitNotification',
  TRANSLATE_SUBTITLE: 'TranslateSubtitleNotification',
  REPLACE_TEXT: 'ReplaceTextNotification',
  PREVIEW_SUBTITLE: 'PreviewSubtitleNotification',
  INSERT_SUBTITLE: 'InsertSubtitleNotification',
  MERGE_SUBTITLE: 'MergeSubtitleNotification',
  SPLIT_SUBTITLE: 'SplitSubtitleNotification',
  DELETE_SUBTITLE: 'DeleteSubtitleNotification',
  DOWNLOAD_AUDIO: 'DownloadAudioNotification',
};

@NotificationHandler(Events.PROJECT_INIT, 'ProjectInitNotificationHandler', 0)
export class ProjectInitNotificationHandler implements INotificationHandler<ProjectInitNotification> {
  async handle(notification: ProjectInitNotification): Promise<void> {
    projectData.reset();
    projectData.voices = (await getAvailSpeakers()).data;
    const res = await getLatestSnapshot(notification.id);
    if (res.code != 0) {
      message.error('网络异常，请刷新后重试!');
      return;
    }

    res.data.payload.id = notification.id;

    const videoUrl = res.data.payload.video_obj_link;
    res.data.payload.video_obj_link = '';
    const videoId = materialManager.getIdByType(notification.id, 'raw_video');
    materialManager.getReousrceFromCache(videoId, '', MaterialType.Video, videoUrl, this.setVideo);

    const audioUrl = res.data.payload.audio_obj_link;
    res.data.payload.audio_obj_link = '';
    const audioId = materialManager.getIdByType(notification.id, 'raw_audio');
    materialManager.getReousrceFromCache(audioId, '', MaterialType.Audio, audioUrl, this.setAudio);

    projectData.setProject(res.data.payload);
    Promise.resolve();
  }

  setVideo(material: Material) {
    const blob = new Blob([material.blob as ArrayBuffer], { type: 'video/mp3' });
    const url = window.URL.createObjectURL(blob);
    projectData.video_obj_link = url;
    projectData.resoucesReady();
  }

  setAudio(material: Material) {
    const blob = new Blob([material.blob as ArrayBuffer], { type: 'audio/wav' });
    const url = window.URL.createObjectURL(blob);
    projectData.audio_obj_link = url;
  }
}

@NotificationHandler(Events.TRANSLATE_SUBTITLE, 'TranslateSubtitleNotificationHandler', 0)
export class TranslateSubtitleNotificationHandler implements INotificationHandler<TranslateSubtitleNotification> {
  async handle(notification: TranslateSubtitleNotification): Promise<void> {
    const res = await translate(
      notification.text,
      projectData.config.sourceLang.toUpperCase(),
      projectData.config.targetLang.toUpperCase(),
    );
    currSubtitle.setTargetLangText(res.data);
    projectData.save();
    message.success('翻译完成！');
    Promise.resolve();
  }
}

@NotificationHandler(Events.REPLACE_TEXT, 'ReplaceTextNotificationHandler', 0)
export class ReplaceTextNotificationHandler implements INotificationHandler<ReplaceTextNotification> {
  async handle(notification: ReplaceTextNotification): Promise<void> {
    projectData.replaceText(notification.text, notification.replacement);
    message.success('替换成功！');
    Promise.resolve();
  }
}

@NotificationHandler(Events.PREVIEW_SUBTITLE, 'PreviewSubtitleNotificationHandler', 0)
export class PreviewSubtitleNotificationHandler implements INotificationHandler<PreviewSubtitleNotification> {
  async handle(notification: PreviewSubtitleNotification): Promise<void> {
    projectData.loading = true;

    const subtitle = projectData.getSubtitle(notification.sectionId) as SubtitleData;
    if (subtitle.audio) {
      this.handlePreviewUrl(notification.sectionId, subtitle.audio);
      return;
    }
    // else if (projectData.audio_obj_link && projectData.audio_obj_link.length > 0) {
    //   const rawAudioId = materialManager.getIdByType(projectData.id, "raw_audio");
    //   materialManager.getReousrceFromCache(rawAudioId, "", MaterialType.Audio, projectData.audio_obj_link,
    //     (material: Material) => {
    //       this.process(material, notification.sectionId)
    //   });
    //   return;
    // }

    const res = await previewAudio(projectData.id, notification.sectionId);
    if (res.code != 0) {
      message.error('语音生成失败，请重试！');
      projectData.loading = false;
      return;
    }
    const url = res.data.objectPath;
    // materialManager.getReousrceFromCache(id, notification.sectionId, MaterialType.Audio, url, this.handlePreview);

    subtitle.audio = url;
    this.handlePreviewUrl(notification.sectionId, url);
    Promise.resolve();
  }

  async process(material: Material, sectionId: string) {
    const subtitle = projectData.getSubtitle(sectionId) as SubtitleData;

    if (!subtitle.originalAudio) {
      const fileopt = await directingEngine.AudioClip(
        'RawAudio.wav',
        material.blob as ArrayBuffer,
        toSeconds(subtitle.timelineCursor.startAt),
        Number(subtitle.timelineCursor.duration) / 1000,
      );
      const callbacks = {
        onProgress: (e: any) => console.log(e),
        onSuccess: (e: any) => {
          console.log(e);
        },
        onError: (e: any) => console.log(e),
      } as any;
      const rawUrl = await uploadFile(
        fileopt,
        RESOURCE_TYPE.RAW_AUDIO_FRAG_UPLOAD,
        callbacks,
        projectData.id,
        sectionId,
      );
      subtitle.originalAudio = rawUrl;
    }

    const res = await previewAudio(projectData.id, sectionId);
    if (res.code != 0) {
      message.error('语音生成失败，请重试！');
      projectData.loading = false;
      return;
    }
    const url = res.data.objectPath;
    // materialManager.getReousrceFromCache(id, notification.sectionId, MaterialType.Audio, url, this.handlePreview);

    subtitle.audio = url;
    this.handlePreviewUrl(sectionId, url);
    Promise.resolve();
  }

  handlePreview(material: Material) {
    const section = projectData.getSubtitle(material.sectionId as string) as SubtitleData;
    const blob = new Blob([material.blob as ArrayBuffer], { type: 'audio/wav' });
    const url = window.URL.createObjectURL(blob);
    section.audio = url;
    message.success('预览成功!');
    projectData.loading = false;
    directingEngine.preview(toSeconds(section.timelineCursor.startAt), section.audio);
  }

  handlePreviewUrl(sectionId: string, url: string) {
    const section = projectData.getSubtitle(sectionId) as SubtitleData;
    section.audio = url;
    message.success('预览成功!');
    projectData.loading = false;
    directingEngine.preview(toSeconds(section.timelineCursor.startAt), section.audio);
  }
}

@NotificationHandler(Events.INSERT_SUBTITLE, 'InsertSubtitleNotificationHandler', 0)
export class InsertSubtitleNotificationHandler implements INotificationHandler<InsertSubtitleNotification> {
  async handle(notification: InsertSubtitleNotification): Promise<void> {
    let subtitle: SubtitleData | undefined;
    let nextSubtitle: SubtitleData | undefined;
    let start = 0;
    let end = 0;
    if (notification.insertPrevious) {
      const previous = projectData.getPreviousSubtitle(currSubtitle.id);
      if (previous == null) {
        start = 0;
      } else {
        subtitle = previous;
        start = toSeconds(previous.timelineCursor.endAt);
      }
      nextSubtitle = projectData.getSubtitle(currSubtitle.id) as SubtitleData;
      end = toSeconds(nextSubtitle.timelineCursor.startAt);
    } else {
      subtitle = projectData.getSubtitle(currSubtitle.id) as SubtitleData;
      start = toSeconds(subtitle.timelineCursor.endAt);
      const next = projectData.getNextSubtitle(currSubtitle.id);
      if (next == null) {
        end = directData.getDuration();
        console.log(end);
      } else {
        nextSubtitle = next;
        end = toSeconds(next.timelineCursor.startAt);
      }
    }

    const duration = Math.round((end - start) * 1000);
    if (duration < 1000) {
      message.warning('插入空间不足, 请重新选择后再试!');
      return;
    }

    let newSubtitle: SubtitleData | undefined;
    if (notification.insertPrevious) {
      if (nextSubtitle) {
        newSubtitle = {
          ...nextSubtitle,
          sectionId: id(),
          sourceLangText: '',
          targetLangText: '',
          order: nextSubtitle.order,
          timelineCursor: {
            startAt: toSrtTimeString(start),
            endAt: toSrtTimeString(end),
            duration: duration,
          },
        } as SubtitleData;
      }
    } else {
      if (subtitle) {
        newSubtitle = {
          ...subtitle,
          sectionId: id(),
          sourceLangText: '',
          targetLangText: '',
          order: subtitle.order + 1,
          timelineCursor: {
            startAt: toSrtTimeString(start),
            endAt: toSrtTimeString(end),
            duration: duration,
          },
        } as SubtitleData;
      }
    }

    const subtitles = [];
    let fixOrder = false;
    for (const sub of projectData.subtitles) {
      if (notification.insertPrevious && !fixOrder) {
        if (subtitle == undefined) {
          subtitles.push(newSubtitle as SubtitleData);
          fixOrder = true;
        } else {
          subtitles.push(sub);
          subtitles.push(newSubtitle as SubtitleData);
          fixOrder = true;
          continue;
        }
      } else {
        if (subtitle && sub.sectionId == subtitle.sectionId) {
          subtitles.push(sub);
          subtitles.push(newSubtitle as SubtitleData);
          fixOrder = true;
          continue;
        }
      }

      if (fixOrder) sub.order++;
      subtitles.push(sub);
    }
    projectData.setSubtitles(subtitles);
    directData.setCurrent(start);
    projectData.save();
    message.success('插入成功!');
    Promise.resolve();
  }
}

@NotificationHandler(Events.MERGE_SUBTITLE, 'MergeSubtitleNotificationHandler', 0)
export class MergeSubtitleNotificationHandler implements INotificationHandler<MergeSubtitleNotification> {
  async handle(notification: MergeSubtitleNotification): Promise<void> {
    if (notification.ids.length < 2) {
      message.warning('无法合并字幕, 请重新选择后再试!');
      return;
    }
    projectData.loading = true;
    const firstSubtitle = projectData.getSubtitle(notification.ids[0]) as SubtitleData;
    const lastSubtitle = projectData.getSubtitle(notification.ids[notification.ids.length - 1]) as SubtitleData;

    const end = toSeconds(lastSubtitle.timelineCursor.endAt);
    const start = toSeconds(firstSubtitle.timelineCursor.startAt);
    const duration = Math.round((end - start) * 1000);

    const newSubtitle = {
      ...firstSubtitle,
      order: firstSubtitle.order,
      sourceLangText: '',
      targetLangText: '',
      timelineCursor: {
        startAt: firstSubtitle.timelineCursor.startAt,
        endAt: lastSubtitle.timelineCursor.endAt,
        duration: duration,
      },
      characterCount: 0,
    } as SubtitleData;

    const subtitles = [];
    let fixOrder = false;
    let mergeFlag = false;
    for (const sub of projectData.subtitles) {
      if (sub.sectionId == firstSubtitle.sectionId) {
        mergeFlag = true;
      }

      if (mergeFlag) {
        newSubtitle.sourceLangText += sub.sourceLangText;
        newSubtitle.targetLangText += sub.targetLangText;
        newSubtitle.characterCount += sub.sourceLangText.length;

        if (sub.sectionId == lastSubtitle.sectionId) {
          mergeFlag = false;
          fixOrder = true;
          newSubtitle.voiceSpeed = calcTargetLanguageReadingSpeed(
            newSubtitle.targetLangText,
            newSubtitle.timelineCursor.duration,
          );
          subtitles.push(newSubtitle);
        }
        continue;
      }

      if (fixOrder) sub.order = sub.order - notification.ids.length + 1;
      subtitles.push(sub);
    }
    projectData.setSubtitles(subtitles);
    directData.setCurrent(toSeconds(newSubtitle.timelineCursor.startAt));
    projectData.save();
    projectData.merging = false;
    projectData.setMergeRange([]);
    projectData.loading = false;
    message.success('合并成功!');
    Promise.resolve();
  }
}

@NotificationHandler(Events.SPLIT_SUBTITLE, 'SplitSubtitleNotificationHandler', 0)
export class SplitSubtitleNotificationHandler implements INotificationHandler<SplitSubtitleNotification> {
  async handle(notification: SplitSubtitleNotification): Promise<void> {
    projectData.loading = true;
    const subtitle = projectData.getSubtitle(currSubtitle.id) as SubtitleData;
    const end = toSeconds(subtitle.timelineCursor.endAt);
    const start = toSeconds(subtitle.timelineCursor.startAt);
    const duration = Math.round((end - start) * 1000);
    if (duration < 2000) {
      message.warning('时间过短，无法拆分，请重新选择后再试!');
      projectData.loading = false;
      return;
    }
    const splitDuration = Math.round(duration / 2);

    const lastEnd = subtitle.timelineCursor.endAt;
    subtitle.timelineCursor.endAt = toSrtTimeString(start + splitDuration / 1000);
    subtitle.timelineCursor.duration = splitDuration;

    const src_text = subtitle.sourceLangText;

    subtitle.sourceLangText = src_text.substring(0, notification.cursor);
    const res = await translate(
      subtitle.sourceLangText,
      projectData.config.sourceLang.toUpperCase(),
      projectData.config.targetLang.toUpperCase(),
    );
    subtitle.targetLangText = res.data;
    subtitle.voiceSpeed = calcTargetLanguageReadingSpeed(
      subtitle.targetLangText, subtitle.timelineCursor.duration
    );

    const new_src_text = src_text.substring(notification.cursor);
    const newRes = await translate(
      new_src_text,
      projectData.config.sourceLang.toUpperCase(),
      projectData.config.targetLang.toUpperCase(),
    );

    const newTargetLangText = newRes.data;
    const newDuration = duration - splitDuration;

    const newSubtitle = {
      ...subtitle,
      sectionId: id(),
      sourceLangText: new_src_text,
      targetLangText: newTargetLangText,
      order: subtitle.order + 1,
      timelineCursor: {
        startAt: subtitle.timelineCursor.endAt,
        endAt: lastEnd,
        duration: newDuration,
      },
      voiceSpeed: calcTargetLanguageReadingSpeed(
        newTargetLangText, newDuration
      ),
      audio: '' // 这里需要将拆分出来的段落生成音频置空
    } as SubtitleData;

    const subtitles = [];
    let fixOrder = false;
    for (const sub of projectData.subtitles) {
      if (sub.sectionId == subtitle.sectionId) {
        subtitles.push(subtitle);
        subtitles.push(newSubtitle);
        fixOrder = true;
        continue;
      }
      if (fixOrder) sub.order++;
      subtitles.push(sub);
    }
    projectData.setSubtitles(subtitles);
    directData.setCurrent(toSeconds(newSubtitle.timelineCursor.startAt));
    projectData.save();
    projectData.loading = false;
    message.success('拆分成功!');
    Promise.resolve();
  }
}

@NotificationHandler(Events.DELETE_SUBTITLE, 'DeleteSubtitleNotificationHandler', 0)
export class DeleteSubtitleNotificationHandler implements INotificationHandler<DeleteSubtitleNotification> {
  async handle(notification: DeleteSubtitleNotification): Promise<void> {
    const subtitles = [];
    let fixOrder = false;
    for (const sub of projectData.subtitles) {
      if (sub.sectionId == currSubtitle.id) {
        fixOrder = true;
        continue;
      }
      if (fixOrder) sub.order--;
      subtitles.push(sub);
    }
    projectData.setSubtitles(subtitles);
    projectData.save();
    message.success('删除成功!');
    Promise.resolve();
  }
}

@NotificationHandler(Events.DOWNLOAD_AUDIO, 'DownloadAudioNotificationHandler', 0)
export class DownloadAudioNotificationHandler implements INotificationHandler<DownloadAudioNotification> {
  async handle(notification: DownloadAudioNotification): Promise<void> {
    projectData.loading = true;

    console.log(projectData.generated_audio_obj_link);
    if (projectData.generated_audio_obj_link && projectData.generated_audio_obj_link.startsWith('http')) {
      const mergedAudioId = materialManager.getIdByType(projectData.id, 'merged_audio');
      materialManager.getReousrceFromCache(
        mergedAudioId,
        '',
        MaterialType.Audio,
        projectData.generated_audio_obj_link,
        this.handleCb,
      );
      return;
    }
    // else if (projectData.audio_obj_link && projectData.audio_obj_link.length > 0) {
    //   const rawAudioId = materialManager.getIdByType(projectData.id, "raw_audio");
    //   materialManager.getReousrceFromCache(rawAudioId, "", MaterialType.Audio, projectData.audio_obj_link,
    //     (material: Material) => {
    //       this.preProcess(material, this.process)
    //   });
    //   return;
    // }

    this.process();
  }

  async preProcess(material: Material, processFn: Function) {
    for (const subtitle of projectData.subtitles) {
      if (subtitle.originalAudio) continue;
      const fileopt = await directingEngine.AudioClip(
        'RawAudio.wav',
        material.blob as ArrayBuffer,
        toSeconds(subtitle.timelineCursor.startAt),
        Number(subtitle.timelineCursor.duration) / 1000,
      );
      const callbacks = {
        onProgress: (e: any) => console.log(e),
        onSuccess: (e: any) => {
          console.log(e);
        },
        onError: (e: any) => console.log(e),
      } as any;
      const rawUrl = await uploadFile(
        fileopt,
        RESOURCE_TYPE.RAW_AUDIO_FRAG_UPLOAD,
        callbacks,
        projectData.id,
        subtitle.sectionId,
      );
      subtitle.originalAudio = rawUrl;
    }
    processFn();
  }

  async process() {
    message.info('生成开始!');
    projectData.subtitles.map((s) => (s.audioMaterial = undefined));
    const res = await genAllAudio(projectData.id);
    if (res.data == 'SUCCEED') {
      // 下载背景音
      const bgAudioId = materialManager.getIdByType(projectData.id, 'raw_bg');
      materialManager.getReousrceFromCache(
        bgAudioId,
        '',
        MaterialType.Audio,
        projectData.audio_obj_link,
        directingEngine.checkMergeBg,
      );

      let counter = 0;
      const timer = setInterval(async () => {
        console.log(`访问接口，当前时间戳：${Date.now()}`);
        counter++;
        const status = await getGenStatus(projectData.id);
        console.log(status);

        if (counter >= 600 || status.data == 'FAILED') {
          clearInterval(timer);
          projectData.loading = false;
          message.error('合成出错，请稍候再试！');
        } else if (status.data == 'SUCCESS') {
          clearInterval(timer);
          // 开始下载
          const res = await getLatestSnapshot(projectData.id);
          projectData.subtitles = res.data.payload.subtitles.map(
            (s) => new SubtitleData(s, projectData.roles, projectData.voices),
          );
          projectData.subtitles.map((s) => {
            if (s.targetLangText != '') {
              const id = materialManager.getIdByVersion(projectData.version, s.sectionId);
              // materialManager.getReousrceFromCache(
              //   id,
              //   s.sectionId,
              //   MaterialType.Audio,
              //   s.audio,
              //   directingEngine.checkMergeResource,
              // );
              materialManager.overwriteResource(
                id,
                s.sectionId,
                MaterialType.Audio,
                s.audio,
                directingEngine.checkMergeResource
              );
            } else {
              s.audioMaterial = new ArrayBuffer(0);
            }
          });
        }
      }, 3000);
    } else if (res.data == 'GENERATING') {
      projectData.loading = false;
      message.warning('当前已有合成任务正在执行，请等待合成完毕后重试！');
    } else {
      projectData.loading = false;
      message.error('合成出错，请稍候再试！');
    }
    Promise.resolve();
  }

  handleCb(material: Material) {
    const now = new Date(); // 获取当前时间
    const timestampMs = now.getTime(); // 获取以毫秒为单位的时间戳
    const objectName = `${material.name}_${timestampMs}${id()}.wav`;
    const fileopt = new File([material.blob as ArrayBuffer], objectName);

    if (!projectData.previewAll) {
      downloadFile(fileopt);
      projectData.loading = false;
    } else {
      projectData.loading = false;
      const url = window.URL.createObjectURL(fileopt);
      directingEngine.preview(directData.current, url);
    }
  }
}
