import { createEventStream, getQAResult } from '@/axios/workbench';
import { id } from '@/utils/id';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

export interface Message {
  sessionId: string;
  text: string;
  isUser: boolean;
}

export class ChatMessages {
  data: Message[] = [];

  constructor() {
    makeAutoObservable(this);
    this.data = [];
  }

  clear() {
    this.data = []
  }

  userInput(text: string) {
    if (text.length < 1 && this.data.length > 0 && this.data[this.data.length - 1].isUser) {
      this.data = this.data.slice(0, this.data.length - 1)
      return;
    }

    if (this.data.length == 0) {
      this.data.push({sessionId: id(), isUser: true, text });
      return;
    }

    const lastMessage = this.data[this.data.length - 1];
    if (lastMessage.isUser) {
      lastMessage.text = text;
    } else {
      this.data.push({sessionId: id(), isUser: true, text });
    }
  }

  sendMessage(projectId: string, text: string) {
    const sessionId = id();
    this.data.push({sessionId: sessionId, isUser: false, text: "" });
    createEventStream(projectId, sessionId);
    getQAResult(sessionId, text, projectId);
  }

  setResult(session_id: string, text: string) {
    const session = this.data.find(s => s.sessionId == session_id);
    if (session) {
      session.text += text; 
    }
  }
}

export const chatMessages = new ChatMessages();
