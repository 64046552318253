import axios from 'axios';
import { GETDNS_REQUEST, NOS_GETDNS_HOST, NOS_HTTPS_DNS } from './constants';
import request from './request';
import { round } from './utils';

export const blobSlice = File.prototype.slice;

export class NosUploader{
  _options = {
    chunkSize: 10 * 1024 * 1024,
    retryCount: 3,
  };
  _file: File;
  _bucket: string;
  _token: string;
  _uploadPath: string;

  _isAbort = false;
  // 以文件 offset 为 key，保存每个分块的上传任务
  _taskStore: { [key: string]: any} = {};
  _onProgress: Function;
  _onSuccess: Function;
  _onError: Function;

  /**
   * @param {File} [options.file] 上传文件
   * @param {Function} onProgress 上传过程处理
   * @param {Function} onSuccess 上传成功处理
   * @param {Function} onError 上传错误处理
   * @param {Object} options 上传配置
   */
  constructor (file: File, bucket: string, token: string, upload_path: string, onProgress: Function, onSuccess: Function, onError: Function, options?: any) {
    if (options) this._options = options;
    this._file = file;

    this._bucket = bucket;
    this._token = token;
    this._uploadPath = upload_path;

    this._onProgress = onProgress;
    this._onSuccess = onSuccess;
    this._onError = onError;
    this._isAbort = false;
  }

  _getDNS = (bucket: string) => {
    const service = axios.create({
      baseURL: NOS_GETDNS_HOST,
    });
    return service.get(`${GETDNS_REQUEST}${bucket}`);
  }
  
  _uploadDirect = async () => {
    try {
      const objectName = this._uploadPath;
      const bucket = this._bucket;
      const token = this._token;
      /**
       * 1. get best dns to upload
       */
      let dnsUrl;
      if (process.env.NODE_ENV === 'development') {
        const dnsRet = await this._getDNS(bucket);
        dnsUrl = dnsRet.data.upload[0];
      } else {
        dnsUrl = NOS_HTTPS_DNS;
      }
      
      let offset = 0;
      const fileSize = this._file.size;
      const { chunkSize } = this._options;
      let complete = false;
      let context = '';
      while (!complete) {
        if (this._isAbort) return;
        const chunkEnd = offset + chunkSize;
        complete = chunkEnd >= fileSize;
        const blob = blobSlice.call(this._file, offset, chunkEnd);
        const { offset: nextOffset, context: _context } = await this._uploadChunkDirect({
          blob,
          url: dnsUrl,
          bucketName: bucket,
          objectName: encodeURIComponent(objectName),
          token,
          complete,
          offset,
          context
        }, {
          onProgress: ({ loaded, total }: any) => {
            const totalLoaded = loaded + offset
            const percent = round(totalLoaded / fileSize * 100, 2)
            this._onProgress.call(null, { loaded: round(totalLoaded), total: fileSize, percent })
          }
        })
        offset = nextOffset
        context = _context
      }
  
      this._onSuccess.call(null, { file: this._file, bucketName: bucket, objectName })
    } catch (error) {
      this._onError.call(null, error)
    }
  }

   /**
   * 直传上传分块
   * @param {Object} params
   * @param {Blob} [params.blob] 分块
   * @param {String} [params.bucketName] 桶名
   * @param {String} [params.objectName] 对象名
   * @param {String} [params.token] token
   * @param {Number} [params.offset] 该分块在整个文件中的偏移
   * @param {Boolean} [param.complete] 是否是最后一片
   * @param {Object} callbacks
   * @param {Function} [callbacks.onProgress]
   */
   async _uploadChunkDirect (params: any, callbacks = {}) {
    const { blob, bucketName, objectName, token, offset, complete, context = '' } = params
    const { onProgress } = callbacks as any
    let { retryCount } = this._options
    const url = `${params.url}/${bucketName}/${objectName}?offset=${offset}&complete=${complete}&context=${context}&version=1.0`

    const run = async () => {
      try {
        const res = await request(url, {
          method: 'POST',
          headers: {
            'x-nos-token': token,
          },
          onCreate: (xhr: { abort: () => void; }) => {
            this._taskStore[offset] = {
              offset,
              abort () {
                xhr.abort()
              }
            }
          },
          onProgress,
          body: blob
        })
        delete this._taskStore[offset]
        return res.data
      } catch (error) {
        if (retryCount > 0) {
          retryCount--
          await run()
        } else {
          return Promise.reject(new Error(`[nos-uploader]: 上传分块失败，offset ${offset}`))
        }
      }
    }

    return run()
  }

  start () {
    this._uploadDirect()
  }

  abort () {
    const tasks = Object.values(this._taskStore)
    tasks.forEach((task: any) => {
      task.abort();
    })
    this._isAbort = true
    this._taskStore = {}
  }
}