import s from './style.module.css';
import { observer } from 'mobx-react-lite';
import { Avatar } from 'antd';
import Logo from "./icons/logo.png"
import cn from "classnames"

const ChatMessage: React.FC<any> = observer((props: {isUser: boolean, text: string}) => {
  const avatar = props.isUser ? <Avatar crossOrigin="anonymous" className={cn(s.avatar, props.isUser ? "": s.avatarBot)} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" /> :
    <Avatar crossOrigin="anonymous" className={cn(s.avatar, props.isUser ? "": s.avatarBot)} src={Logo} />;
    
  return (
    <div className={cn(s.container, props.isUser ? "": s.bot)}>
      <div className={s.msgBody}>
        {props.isUser ? <>
          <div className={s.text}>
            {props.text == "" && <span className={s.loadingIcon} />}
            {props.text}
          </div>
          {avatar}
        </> : <>
        {avatar}
        <div className={s.text}>
          {props.text == "" && <span className={s.loadingIcon} />}
          {props.text}
        </div>
        </>}
      </div>
    </div>
  );
});

export default ChatMessage;
