import userStore from '@/stores/user';
import service, { ApiResponse, TENANT_ID, getToken } from './service';
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { chatMessages } from '@/stores/data/chat-messages';

export const getSummary = (project_id: string): Promise<ApiResponse<any>> => {
  const team_id = userStore.info?.teamId;
  return service.get<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/${team_id}/${project_id}/summary`);
};

export const createEventStream = async (project_id: string, session_id: string): Promise<any> => {
  const team_id = userStore.info?.teamId;
  const ctrl = new AbortController();
  let endFlag = false;
  await fetchEventSource(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/${team_id}/${project_id}/qa/session/${session_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'Cross-Origin-Resource-Policy': 'cross-origin',
      'Tenant-Id': TENANT_ID.toString(),
    },
    openWhenHidden: true, // 取消visibilityChange事件
    signal: ctrl.signal, // AbortSignal
    async onmessage(ev) {
      if (ev.data) {
        const data = JSON.parse(ev.data);
        if (data.result.response != null) {
          if (!endFlag) {
            chatMessages.setResult(session_id, data.result.response);
          }
        } else {
          endFlag = true;
        }
      }
    },
    onclose() {
      
    },
    onerror(err) {
      console.log(err)
      throw err // 直接抛出错误，避免反复调用
    }
    })
}

export const getQAResult = (session_id: string, question: string, project_id: string): Promise<ApiResponse<any>> => {
  const team_id = userStore.info?.teamId;
  return service.post<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/${team_id}/${project_id}/qa/completion`, {
    session_id,
    question,
    "prompt": "",
  });
};


// export const getQAResult = async (question: string, project_id: string, msg_idx: number): Promise<any> => {
//   const ctrl = new AbortController()
//   const team_id = userStore.info?.teamId;
  // await fetchEventSource(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/${team_id}/${project_id}/qa/completion`, {
  // method: 'POST',
  // headers: {
  //   'Content-Type': 'application/json',
  //   Authorization: `Bearer ${getToken()}`,
  //   'Cross-Origin-Resource-Policy': 'cross-origin',
  //   'Tenant-Id': TENANT_ID.toString(),
  // },
  // body: JSON.stringify({
  //   "question": question,
  //   "prompt": "",
  // }),
  // openWhenHidden: true, // 取消visibilityChange事件
  // signal: ctrl.signal, // AbortSignal
  // async onmessage(ev) {
  //   const data = JSON.parse(ev.data);
  //   console.log(data);
  //   if (data.result.response.length == 1) {
  //     chatMessages.setResult(msg_idx, data.result.response);
  //   }
  // },
  // onclose() {
    
  // },
  // onerror(err) {
  //   console.log(err)
  //   throw err // 直接抛出错误，避免反复调用
  // }
  // })
// }