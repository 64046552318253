import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Divider, Input, Pagination, Table, message } from 'antd';
import { RouteComponent } from '@/routes/RootRouter';
import s from './style.module.css';
import MainLayout from '@/layout';
import { ColumnsType } from 'antd/lib/table';
import { TeamAccount, deleteTeamAccount, fetchTeamAccounts } from '@/axios/team';
import AddModal from './add-modal';
import { usePagination } from 'ahooks';
import { observer } from 'mobx-react-lite';
import EditModal from './edit-modal';
import ResetPasswordModal from './reset-password-modal';

interface AccountInfo {
  key: React.Key;
  name: string;
  contact: string;
  mail: string;
  createTime: string;
}

const TeamManagementPage: React.FC<RouteComponent> = observer(({ routes }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [editAccount, setEditAccount] = useState<AccountInfo>();

  const [timeStamp, setTimeStamp] = useState(Date.now());
  const [name, setName] = useState("");
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();

  const toDate = (time: number) => new Date(time).toLocaleString();

  const getTeamAccountList = (params: {
    current: number;
    pageSize: number;
  }): Promise<{ total: number; list: TeamAccount[] }> => {
    return new Promise(async (resolve) => {
      const result = await fetchTeamAccounts({
        page_no: params.current,
        page_size: params.pageSize,
        nickname: name,
        start_time: startTime ? startTime.format("yyyy-MM-DD HH:mm:ss") : "",
        end_time: endTime ? endTime.format("yyyy-MM-DD HH:mm:ss") : "",
      });
      resolve(result.data);
    });
  };

  const { data, loading, pagination } = usePagination(getTeamAccountList, {
    refreshDeps: [timeStamp]
  });

  const handleAddTeamAccount = () => {
    search();
    setShowAddModal(false);
  };

  const handleEditTeamAccount = () => {
    search();
    setShowEditModal(false);
  };

  const handleResetPassword = () => {
    search();
    setShowResetPasswordModal(false);
  };

  const handleEditClick = (account: AccountInfo) => {
    setEditAccount(account);
    setShowEditModal(true);
  };

  const handleResetClick = (account: AccountInfo) => {
    setEditAccount(account);
    setShowResetPasswordModal(true);
  };


  const handleDeleteTeamAccount = async (id: string) => {
    const res = await deleteTeamAccount(id);
    search();
    message.success("账号删除成功!");
  };

  const columns: ColumnsType<AccountInfo> = [
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '联系方式',
      dataIndex: 'contact',
    },
    {
      title: '邮箱',
      dataIndex: 'mail',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <div >
          <a onClick={e => handleEditClick(record)}>编辑</a>
          <Divider className={s.divider} type="vertical" />
          <a onClick={e => handleResetClick(record)}>重置密码</a>
          <Divider className={s.divider} type="vertical" />
          <a className={s.delText} onClick={(e: any) => handleDeleteTeamAccount(record.key.toString())}>删除</a>
        </div>
      ),
    },
  ];

  const handleSearch = () => {
    search();
  }

  const search = () => {
    setTimeStamp(Date.now());
  }

  return (
    <MainLayout>
      <div className={s.container}>
        <div className={s.actionBar}>
          <span>员工姓名:</span><Input className={s.mailInput} value={name} maxLength={80} onChange={(e) => setName(e.target.value)} placeholder="请输入姓名" />
          <span>日期:</span><DatePicker.RangePicker format="YYYY-MM-DD" value={[startTime, endTime]} onChange={(value: any) => {
            setStartTime(value ? value[0] : "");
            setEndTime(value ? value[1] : "");
          }} />
          <div className={s.actionArea}>
            <Button className={s.actionBtn} type="primary" onClick={handleSearch}>
              搜索
            </Button>
            <Button className={s.actionBtn} type="primary" ghost={true} onClick={e => setShowAddModal(true)}>
              新增
            </Button>
          </div>
        </div>
        <div className={s.resultArea}>
          <Table
            className={s.table}
            columns={columns}
            dataSource={data?.list?.map((acc: TeamAccount) => {
              return {
                key: acc.id,
                name: acc.nickname,
                contact: acc.mobile,
                mail: acc.email,
                createTime: toDate(acc.create_time)
              } as AccountInfo;
            })}
            loading={loading}
            pagination={false}
          />
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={data?.total}
            onChange={pagination.onChange}
            onShowSizeChange={pagination.onChange}
            showSizeChanger
            style={{ marginTop: 16, textAlign: 'right' }}
          />
        </div>
        <AddModal open={showAddModal} onSubmit={handleAddTeamAccount} onCancel={(e: any) => setShowAddModal(false)} />
        <EditModal open={showEditModal} item={editAccount} onSubmit={handleEditTeamAccount} onCancel={(e: any) => setShowEditModal(false)} />
        <ResetPasswordModal open={showResetPasswordModal} item={editAccount} onSubmit={handleResetPassword} onCancel={(e: any) => setShowResetPasswordModal(false)} />
      </div>
    </MainLayout>
  );
});

export default TeamManagementPage;
