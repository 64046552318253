import { CreateProjectRequest } from "@/axios/project";
import { makeAutoObservable } from "mobx";

export class CreateProjectData {
  name = "";
  duration = 0;
  videoUrl = "";
  audioUrl = "";
  subtitleUrl = "";
  
  constructor() {
    makeAutoObservable(this);
  }

  init() {
    this.name = "";
    this.duration = 0;
    this.videoUrl = "";
    this.audioUrl = "";
    this.subtitleUrl = "";
  }

  toRequest() {
    return {
      name: this.name,
      video_obj_link: this.videoUrl,
      audio_obj_link: this.audioUrl,
      subtitle_obj_link: this.subtitleUrl,
      duration: this.duration,
      source_lang: "ZH_CN",
      target_lang: "EN_US",
    } as CreateProjectRequest;
  }
}

export const createProjectData = new CreateProjectData();
