import { previewUploadUrl, RESOURCE_TYPE } from '@/axios/storage';
import { NosUploader } from '@/nos/uploader';
import axios from 'axios';
const https = require('https');

export const uploadFile = async (file: File, type: RESOURCE_TYPE, callbacks: {}, projectId = "1", sectionId = ""): Promise<string> => {
  const { onProgress, onSuccess, onError } = callbacks as any
  const uploadParams: any = await previewUploadUrl(type, projectId, sectionId);
  if (uploadParams.tmp_token) {
    const { bucket, tmp_token, tmp_path } = uploadParams
    console.log(uploadParams)
    const uploader = new NosUploader(file, bucket, tmp_token, tmp_path, onProgress, onSuccess, onError);
    uploader.start();
  } else {
    PutFile.axios(file, uploadParams.upload_path, onSuccess, onError);
  }
  return Promise.resolve(uploadParams.tmp_path);
}

export class PutFile {
  static axios(file: File, url: string, onSuccess: Function, onError: Function) {
    axios
      .put(url, file, {})
      .then(function (response) {
        console.log(`${file.name} 上传成功`, response);
        onSuccess.call(null, { name: file.name })
      })
      .catch(function (error) {
        console.error(`${file.name} 上传失败`, error);
        onError.call(null, error)
      });
  }
}
