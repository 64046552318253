import { ProjectMeta, retryProjects } from '@/axios/project';
import s from './style.module.css';
import ReactPlayer from 'react-player/lazy';
import { Spin, message } from 'antd';
import { useMemo, useRef } from 'react';
import { useSize } from 'ahooks';

const Workitem: React.FC<any> = (data: ProjectMeta) => {
  const ref = useRef(null);
  const size = useSize(ref);
  const length = Math.round((size?.width as number - 58) / 16);

  const isSucceed = data.status === "INITIALIZED";
  const isFailed = data.status === "INITIALIZATION_FAILED";

  const handleRedirect = (e: MouseEvent, id: string) => {
    console.log(e);
    if ((e.target as any).classList[0].includes("react-player") || !isSucceed) {
      return;
    }
    (window as any).location = `/project/${id}`;
  }

  const toDate = (time: number) => new Date(time).toLocaleString();

  const handleRetry = () => {
    retryProjects(data.id);
    message.info("重试成功，请稍等片刻!");
  }

  const video = useMemo(() => {
    return (
    <ReactPlayer
      className={s.itemImgWrap}
      controls={true}
      playsinline={true}
      light={true}
      url={data.video_obj_link}
      config={{
        file: {
          attributes: {
            crossOrigin: "true",
            controlsList: 'nodownload'
          }
        }
      }}
    />);
  }, [data.id]);

  return (
    <div className={s.container} onClick={(e: any) => handleRedirect(e, data.id)}>
      <div className={s.preview}>
        {video}
      </div>
      <div className={s.meta} ref={ref}>
        <div className={s.title} title={data.name}>{data.name.length > length ? `${data.name.slice(0, length)}...` : data.name}</div>
        <div className={s.uploadtime}>上传时间：{toDate(data.create_time)}</div>
        <div className={s.uploadtime}>更新时间：{toDate(data.update_time)}</div>
        <div className={s.status}>
          <div className={isSucceed ? s.succeed : isFailed ? s.error : s.info}>
            {data.initialize_message}
          </div>
          {
            isSucceed ? <div className={s.succeedIcon} /> : isFailed ? <div className={s.errorIcon} onClick={handleRetry}/> : <Spin />
          }
        </div>
      </div>
    </div>
  );
};

export default Workitem;
 