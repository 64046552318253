import { DispatchInstance } from '@/stores/mediator-system/dispatch-instance';

export interface IDispatcher {
  retrieveAll(name: string): DispatchInstance[];

  register(instance: DispatchInstance): void;

  deregister(name: string): void;

  flush(): void;
}

function byOrder(instance: DispatchInstance, another: DispatchInstance): number {
  if (instance.order < another.order) {
    return -1;
  } else if (instance.order > another.order) {
    return 1;
  }
  return 0;
}

export class Dispatcher implements IDispatcher {
  private _instances: DispatchInstance[] = [];

  deregister(name: string): void {
    this._instances = this._instances.filter((instance) => instance.handlerName != name);
  }

  flush(): void {
    this._instances = [];
  }

  retrieveAll(name: string): DispatchInstance[] {
    const targets = this._instances.filter((instance) => instance.name === name);
    if (targets.length === 0) {
      throw new Error(`无法找到事件"${name}"的处理程序`);
    }
    return targets.sort(byOrder);
  }

  register(instance: DispatchInstance): void {
    this._instances.push(instance);
  }
}

export const dispatcher = new Dispatcher();
