/**
 * 从装修接口拿到的舞台数据
 */
class PlayerSize {
  containerWidth!: number;
  containerHeight!: number;
  screenScale = 1; // 屏幕RESIZE导至的比例放大或是缩小
  selectSacle = 1;
  isFullScreen = false;
  width!: number;
  height!: number;
  videoRatio = 0;
  x = 0;
  y = 0;
}

export const playerSize = new PlayerSize();
