export const downloadFile = (file: File) => {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');//创建a标签
    link.style.display = 'none';
    link.href = url;
    link.download = (file as File).name;
    document.body.appendChild(link);  
    link.click();
    URL.revokeObjectURL(link.href); 
    document.body.removeChild(link);
}

export const downloadUrl = (url: string) => {
    const link = document.createElement('a');//创建a标签
    link.style.display = 'none';
    link.href = url;
    link.download = "generated.mp3";
    document.body.appendChild(link);  
    link.click();
    URL.revokeObjectURL(link.href); 
    document.body.removeChild(link);
}

export const writeFile = (fileName: string, content: any) => {
    const a = document.createElement("a");
    const blob = new Blob([content], { type: "text/plain" });
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
    URL.revokeObjectURL(a.href); 
    // document.body.removeChild(a);
    return blob;
};
