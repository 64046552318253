export enum Unit {
  'ms' = 'ms', // 毫秒
  's' = 's', // 秒
  'frame' = 'frame', // 帧
  'hms' = 'hms', // 时分秒
}

export const toMS = (value: number, unit: Unit) => {
  switch (unit) {
    case Unit.ms:
      return value;
    case Unit.s:
      return value * 1000;
    case Unit.frame: // 以1秒帧60帧来算
      return value / 60 / 1000;
    // case Unit.hms:
    //   return toHMS(value)
    default:
      return value;
  }
};

// value
export const toValue = (msValue: number, unit: Unit) => {
  switch (unit) {
    case Unit.ms:
      return msValue;
    case Unit.s:
      return msValue * 1000;
    case Unit.frame: // 以1秒帧60帧来算
      return msValue * 60 * 1000;
    default:
      return msValue;
  }
};

/*
 * 时间转换成在时间轴上的px值
 */
export const timeToTimeLinePx = (sValue: number, scale: number) => {
  return sValue * scale;
};

/*
 * timeToTimeLinePx的逆操作。返回时间是秒
 */
export const pxToTimeLineTime = (px: number, scale: number) => {
  return px / scale;
};

export const toHMS = (s: number) => {
  let t = '';
  if (s > -1) {
    const hour = Math.floor(s / 3600 / 1000);
    const min = Math.floor(s / 60 / 1000) % 60;
    const sec = Math.floor(s / 1000) % 60;
    const mm = Math.floor(s % 1000);
    if (hour < 10) {
      t = '0' + hour + ':';
    } else {
      t = hour + ':';
    }
    if (min < 10) {
      t += '0';
    }
    t += min + ':';
    if (sec < 10) {
      t += '0';
    }
    t += sec;
  }
  return t;
};

export const toHour = (s: number) => {
  return Math.floor(s / 3600);
}

export const toMinute = (s: number) => {
  return Math.floor(s / 60) % 60;
}

export const toSecond = (s: number) => {
  return Math.floor(s) % 60;
}

export const toSeconds = (srtTime: string) => {
  const vals = srtTime.split(",");
  if (vals.length < 2) return -1;
  const milSeconds = parseInt(vals[1])
  const array = vals[0].split(":");
  if (array.length < 3) return -1;
  const seconds = (parseInt(array[0], 10) * 60 * 60) + (parseInt(array[1], 10) * 60) + parseInt(array[2], 10) + (milSeconds / 1000)
  if (seconds == 0) return 0;
  if (!seconds) return -1;
  return seconds;
}

export const toSrtTimeString = (seconds: number) => {
  let milliseconds = seconds * 1000;
  
  seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  milliseconds = Math.round(milliseconds % 1000);
  seconds = seconds % 60;
  minutes = minutes % 60;
  return (hours < 10 ? '0' : '') + hours + ':'
        + (minutes < 10 ? '0' : '') + minutes + ':'
        + (seconds < 10 ? '0' : '') + seconds + ','
        + (milliseconds < 100 ? '0' : '') + (milliseconds < 10 ? '0' : '') + milliseconds;
}
