import service, { ApiResponse } from "./service";

export const getQuota = (): Promise<ApiResponse<any>> => {
    return service.get<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/teams/v1alpha1/quota`);
};

export const fetchRechargeRecords = (request: any): Promise<ApiResponse<any>> => {
    return service.get<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/recharge-record/v1alpha1`, {
        params: request
    });
};

export const fetchRechargePackages = (request: any): Promise<ApiResponse<any>> => {
    return service.get<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/recharge-packet/v1alpha1`, {
        params: request
    });
};

export const fetchRechargeText = (): Promise<ApiResponse<any>> => {
    return service.get<ApiResponse<any>>(`/apis/aivt.digitalnotee.api/recharge-text/v1alpha1`);
};
