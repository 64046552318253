import HandlerInstance from '@/stores/mediator-system/handler-instance';
import { getLogger, Logger, Scope } from '../log/log';

export interface IRegistry {
  retrieve<T>(name: string): T | Function;

  register(name: string, handleFunc: Function): void;

  deregister(name: string): void;

  flush(): void;
}

export class Registry implements IRegistry {
  private _instance: HandlerInstance[] = [];
  private _logger: Logger = getLogger(Scope.Mediator);

  deregister(name: string): void {
    const idx = this._instance.findIndex((instance) => instance.name === name);
    if (idx >= 0) {
      this._instance.splice(idx, 1);
    }
  }

  flush(): void {
    this._instance = [];
  }

  register(name: string, handleFunc: Function): void {
    const existed = this._instance.find((instance) => instance.name === name);
    if (existed === undefined) {
      this._instance.push(new HandlerInstance(name, handleFunc));
    } else {
      throw new Error(`为事件"${name}"注册处理函数失败，此事件已存在对应处理函数`);
    }
  }

  retrieve<T>(name: string): T | Function {
    const matched = this._instance.find((instance) => instance.name === name);
    if (!matched) {
      // TODO
      // this._logger.debug('当前Registry中的所有实例：{}', this._instance);
      console.log(this._instance);
      console.log(`无法找到事件"${name}"对应的处理函数`);
      return new Function();
    }
    const handlerFunc: any = matched.func;
    // FIXME: 需要一个更好的方式
    if (
      matched.name.endsWith('INDICATOR_CHANGED_HANDLER') ||
      matched.name.endsWith('SEGMENT_CREATED_HANDLER') ||
      matched.name.endsWith('DIRECT_STATUS_CHANGED_HANDLER')
    ) {
      return handlerFunc;
    }
    return new handlerFunc();
  }
}

export const registry = new Registry();
