import React, { useState, useEffect } from 'react';
import { Avatar, Button } from 'antd';
import { RouteComponent } from '@/routes/RootRouter';
import s from './style.module.css';
import MainLayout from '@/layout';
import { getInfo, userLogout } from '@/axios/user';
import ResetPasswordModal from '../team-management/reset-password-modal';
import userStore from '@/stores/user';
import LOGO from '@/layout/top-bar/icons/logo.png';
import { useHistory } from 'react-router';
import { URL } from '@/routes'

const CentrePage: React.FC<RouteComponent> = ({ routes }) => {
  const history = useHistory();
  
  const [userInfo, setUserInfo] = useState({
    nickname: "",
    email: "",
    mobile: ""
  });

  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  useEffect(() => {
    getInfo().then(res => {
      if (res.code == 0) setUserInfo({
        ...res.data,
        key: userStore.info?.id
      })
    });
  }, [])

  const handleResetPassword = () => {
    setShowResetPasswordModal(false);
  };

  const onLogout = async () => {
    await userLogout();
    history.push(URL.home);
  };

  return (
    <MainLayout>
      <div className={s.container}>
        <div className={s.titleBar}>个人中心</div>
        <div className={s.infoBar}>
          <Avatar className={s.avatar} crossOrigin="anonymous" src={LOGO} />
          <div className={s.infoGroup}>
            <div className={s.nameText}>
              {userInfo.nickname}
            </div>
            <div className={s.actions}>
              <Button className={s.button} type="primary" onClick={() => setShowResetPasswordModal(true)}>
                修改密码
              </Button>
              <Button className={s.button} type="primary" onClick={onLogout}>
                登出
              </Button>
            </div>           
          </div>
          <div className={s.infoGroup}>
            <div className={s.infoText}>
              账号：{userInfo.mobile}
            </div>
            <div className={s.infoText}>
              邮箱：{userInfo.email}
            </div>
          </div>
          <ResetPasswordModal open={showResetPasswordModal} item={userInfo} onSubmit={handleResetPassword} onCancel={(e: any) => setShowResetPasswordModal(false)} />
        </div>
      </div>
    </MainLayout>
  );
};

export default CentrePage;
