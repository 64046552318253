import React, { useState, useCallback, FC } from 'react';
import TopBar from './top-bar';
import s from './style.module.css';
import { Spin } from 'antd';
import { projectData } from '@/stores/data/project-data';
import { observer } from 'mobx-react-lite';

interface Props {
  children: React.ReactNode;
}

const MainLayout: FC<Props> = observer(({children}) => {
  return (
    <div className={s.container}>
      <div className={s.main}>
        <TopBar />
        <Spin spinning={projectData.loading} wrapperClassName={s.spinWrapper} size={'large'}>
          <div className={s.content}>
              {children}
          </div>
        </Spin>
      </div>
    </div>
  );
});

export default MainLayout;
