interface CompressionStream extends GenericTransformStream { _: any }
type CompressionFormat = "deflate" | "deflate-raw" | "gzip";
declare const CompressionStream: {
  prototype: CompressionStream;
  new(format: CompressionFormat): CompressionStream
}

interface DecompressionStream extends GenericTransformStream { _: any }
declare const DecompressionStream: {
  prototype: DecompressionStream;
  new(format: CompressionFormat): DecompressionStream
}

export const compress = (data: string) => {
  const blobToBase64 = (blob: Blob) => new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result && (reader.result as string).split(',')[1]);
    reader.readAsDataURL(blob);
  });
  const byteArray = new TextEncoder().encode(data);
  const cs = new CompressionStream('gzip');
  const writer = cs.writable.getWriter();
  writer.write(byteArray);
  writer.close();
  return new Response(cs.readable).blob().then(blobToBase64);
};

export const decompress = (base64string: string) => {
  const bytes = Uint8Array.from(atob(base64string), c => c.charCodeAt(0));
  const cs = new DecompressionStream('gzip');
  const writer = cs.writable.getWriter();
  writer.write(bytes);
  writer.close();
  return new Response(cs.readable).arrayBuffer().then(function (arrayBuffer) {
    return new TextDecoder().decode(arrayBuffer);
  });
}
