import React, { useState, useEffect } from 'react';
import { RouteComponent } from '@/routes/RootRouter';
import s from './style.module.css';
import MainLayout from '@/layout';
import { fetchNotifications } from '@/axios/notification';

const toDate = (time: number) => new Date(time).toLocaleString();

const NotificationPage: React.FC<RouteComponent> = ({ routes }) => {
  const [notifications, setNotifications] = useState<any[]>([]);

  const getNotifications = (params: {
    current: number;
    pageSize: number;
  }): Promise<{ total: number; list: any[] }> => {
    return new Promise(async (resolve) => {
      const result = await fetchNotifications({
        pageNo: params.current,
        pageSize: params.pageSize,
        start_time: "",
        end_time: "",
      });
      setNotifications(result.data.list?.map((notification: any) => {
        return {
          title: notification.title,
          time: toDate(notification.createTime),
          text: notification.content,
        }
      }))
      resolve(result.data);
    });
  };

  useEffect(() => {
    console.log("init records")
    getNotifications({current: 1, pageSize: 999999})
  }, [])


  // const getNotifications = (params: {
  //   current: number;
  //   pageSize: number;
  // }): Promise<{ total: number; list: Notification[] }> => {
  //   return new Promise(async (resolve) => {
  //     const result = await fetchNotifications({
  //       pageNo: params.current,
  //       pageSize: params.pageSize,
  //     });
  //     resolve(result.data);
  //   });
  // };

  return (
    <MainLayout>
      <div className={s.container}>
        <div className={s.titleBar}>消息中心</div>
        {notifications.map((x, idx) => 
          (<div className={s.notifyBar} key={idx}>
            <div className={s.notifyTitle}>{x.title}</div>
            <div className={s.notifySubTitle}>{x.subTitle}</div>
            <div className={s.notifyContent}>
              {x.time}
              <div className={s.notifyText}>{x.text}</div>
            </div>
          </div>)
        )}
      </div>
    </MainLayout>
  );
};

export default NotificationPage;
