import { useState } from 'react';
import s from './style.module.css';
import ImportModal from './import-modal';

const ImportMaterial: React.FC<any> = ({ onSubmit }: any) => {
	const [showModal, setShowModal] = useState(false)

	const handleUpload = () => {
		setShowModal(true)
	}

	const handleSubmit = () => {
		setShowModal(false)
		onSubmit();
	}

	const handleCancel = () => {
		setShowModal(false)
	}
	
	return (
		<>
		<div className={s.container} onClick={handleUpload}>
			<div className={s.centreBox}>
				<div className={s.plusImage} />
				<span className={s.title}>添加视频</span>
			</div>
			<div className={s.detail}>
				支持MP4、MOV、MKV格式，视频≤1GB，时长≤60分钟；对照文件（.srt、.ass、.txt）。
			</div>
		</div>
		<ImportModal open={showModal} onSubmit={handleSubmit} onCancel={handleCancel} />
		</>
	);
};

export default ImportMaterial;