import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import cn from 'classnames'
import s from './style.module.css';
import { useResetState } from 'ahooks';
import { CreateAccountRequest, EditAccountRequest, addTeamAccount, editTeamAccount } from '@/axios/team';
import userStore from '@/stores/user';
import { useEffect, useState } from 'react';
import { Col, Input, Row, message } from 'antd';

const EditModal: React.FC<any> = (props: { open: boolean, item: any, onSubmit: Function, onCancel: Function }) => {
  const [state, setState, resetState] = useResetState<any>({
    mobile: "",
    email: "",
    nickname: "",
  });

  useEffect(() => {
    console.log(props.item)
    if (!props.open || !props.item) return;
    const { contact, mail, name } = props.item;
    setState({
      mobile: contact,
      email: mail,
      nickname: name
    });
  }, [props.open])

  const [loading, setLoading] = useState(false);
  
  const handleSubmit = () => {
    editTeamAccount({
      id: props.item.key,
      email: state.email,
      mobile: state.mobile,
      nickname: state.nickname,
    } as EditAccountRequest).then(res => {
      if (res.code == 0) {
        message.success("编辑账号成功!");
        resetState();
        props.onSubmit();
      } else {
        console.log(res);
        message.error(res.msg);
      }
    });
  };

  const handleCancel = () => {
    resetState();
    props.onCancel();
  };

  return (
    <>
    <Modal
      className={s.modal}
      open={props.open}
      title="编辑团队账号"
      centered
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <div className={s.modalBody}>
        <Row className={s.form}>
          <Col span={12}>
            <Row className={s.dataRow}>
              <Col className={s.title}>员工姓名：</Col>
              <Col><Input className={s.input} value={state.nickname} placeholder={"请输入员工姓名"} maxLength={30} onChange={(e: any) => setState({ ...state, nickname: e.target.value })}></Input></Col>
            </Row>
            <Row className={s.dataRow}>
              <Col className={s.title}>邮箱：</Col>
              <Col><Input className={s.input} value={state.email} placeholder={"请输入邮箱"} maxLength={80} onChange={(e: any) => setState({ ...state, email: e.target.value })}></Input></Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row className={s.dataRow}>
              <Col className={s.title}>联系方式：</Col>
              <Col><Input className={s.input} value={state.mobile} placeholder={"请输入联系方式"} maxLength={30} onChange={(e: any) => setState({ ...state, mobile: e.target.value })}></Input></Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className={s.modalFooter}>
        <Button className={s.cancelBtn} onClick={handleCancel}>
          取消
        </Button>
        <Button className={s.submitBtn} type="primary" loading={loading} onClick={handleSubmit}>
          确认
        </Button>
      </div>
    </Modal>
    </>
  );
};

export default EditModal;
