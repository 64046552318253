import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import s from './style.module.css';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useLockFn } from 'ahooks';


const ConfirmModal: React.FC<any> = (props: { show: boolean, onSubmit: Function, onCancel: Function }) => {
  return (
    <Modal
      className={s.modal}
      open={props.show}
      title=""
      centered
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <div className={s.modalBody}>
        <div><ExclamationCircleFilled className={s.icon} /></div>
        <div className={s.text}>
          <div className={s.info}>请确认翻译后的视频内容</div>
          <div className={s.highlight}>提交确认后将进行识别翻译并扣除账户的翻译时长</div>
        </div>
      </div>
      <div className={s.modalFooter}>
        <Button className={s.submitBtn} type="primary" onClick={useLockFn(() => props.onSubmit())}>
          确认
        </Button>
        <Button className={s.cancelBtn} onClick={() => props.onCancel()}>
          取消
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;