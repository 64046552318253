import { useEffect, useRef, useState } from 'react';
import s from './style.module.css';
import { Button, message } from 'antd';
import { projectData } from '@/stores/data/project-data';
import { observer } from 'mobx-react-lite';
import { getSummary } from '@/axios/workbench';

const Summary: React.FC<any> = observer((props: {}) => {
  const initialized = useRef(false)
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any[]>([]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      handleSummary(null);
    }
  }, [])

  const handleSummary = (e: any) => {
    setLoading(true);
    getSummary(projectData.id).then((res: any) => {
      if (res.code == 0) {
        setSummary(res.data);
        setLoading(false);
        message.success("摘要成功!");
      }
    });
  };


  return (
    <div className={s.container}>
      <Button className={s.actionBtn} onClick={e => handleSummary(e)} loading={loading}>
        生成摘要
      </Button>
      <div className={s.paragraph}>
        {summary}
      </div>
    </div>
  );
});

export default Summary;