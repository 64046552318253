import { SubtitleData } from "@/stores/model/project";
import { writeFile } from "./file";

export const WriteSrt = (subtitles: SubtitleData[]) => {
    const srtObjs = subtitles.sort((a, b) => a.order - b.order).map(s => {
        const lines = [];
        lines.push(s.order)
        lines.push(`${s.timelineCursor.startAt} --> ${s.timelineCursor.endAt}`)
        lines.push(s.targetLangText)
        lines.push("")
        return lines.join("\r\n");
    });
    return writeFile("test.srt", srtObjs.join("\r\n"))
}
