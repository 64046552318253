import { makeAutoObservable } from 'mobx';
import { mediator } from '../mediator-system';
import { EmotionData, ProjectConfigData, RoleData, SubtitleData } from '../model/project';
import { ProjectSnapshot, Role, SensitiveWordData, SpeakerInfo, Subtitle, saveSnapshot } from '@/axios/project';
import { DeleteSubtitleNotification, DownloadAudioNotification, InsertSubtitleNotification, MergeSubtitleNotification, PreviewSubtitleNotification, ProjectInitNotification, ReplaceTextNotification, SplitSubtitleNotification, TranslateSubtitleNotification } from '../notification/project';
import { Events } from '../handler/project';
import currSubtitle from './curr-subtitle';
import { calcTargetLanguageReadingSpeed } from '@/utils/speakSpeed';
import { toSeconds, toSrtTimeString } from '@/utils/time';
import { directData } from './direct-data';

const DEAFULT_EMOTION = "默认情感";

export class ProjectData {
  loading = false;
  previewAll = false;
  merging = false;
  mergeSubtitleIds: string[] = [];

  id = "";
  name = ""; // 工程名
  version = 0; // 版本
  config = { sourceLang: 'zh', targetLang: 'en'}; // 翻译配置
  video_obj_link = ""; // 视频源
  audio_obj_link = ""; // 音频源
  bg_obj_link = ""; // 背景声
  bgMaterial: ArrayBuffer | undefined;

  subtitle_obj_link = "";
  roles: RoleData[] = []; // 角色配置
  subtitles: SubtitleData[] = []; // 字幕配置
  subtitleObjects: string[][] = []; // 对照问题
  sensitive_word_settings: SensitiveWordData[] = []; //敏感词
  knowledge_base_id = "";
  generated_audio_obj_link = "";

  duration = 0;

  voices: SpeakerInfo[] = []; // 角色列表

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.video_obj_link = "";
    this.audio_obj_link = "";
    this.roles = [];
    this.subtitles = [];
    this.subtitleObjects = [];
    this.sensitive_word_settings = [];
  }

  setProject(obj: ProjectSnapshot) {
    const { id, name, version, source_lang, target_lang, video_obj_link, audio_obj_link, bg_obj_link, subtitle_obj_link, roles, subtitles, subtitleObjects, sensitive_word_settings, knowledge_base_id, generated_audio_obj_link } = obj;
    this.id = id;
    this.name = name;
    this.version = version;
    this.config = new ProjectConfigData(source_lang, target_lang);
    this.video_obj_link = video_obj_link;
    this.audio_obj_link = audio_obj_link;
    this.bg_obj_link = bg_obj_link;

    this.subtitle_obj_link = subtitle_obj_link;
    this.roles = roles.map(r => new RoleData(r));
    this.subtitles = subtitles.map(s => new SubtitleData(s, this.roles, this.voices));
    this.subtitleObjects = subtitleObjects;
    this.sensitive_word_settings = sensitive_word_settings;
    this.knowledge_base_id = knowledge_base_id;

    this.generated_audio_obj_link = generated_audio_obj_link;
  }

  setDuration(duration: number) {
    this.duration = duration;
  }

  addRole() {
    let maxId = 0;
    for (const role of this.roles) {
      const roleId = Number(role.id.split("_")[1]);
      if (roleId > maxId) {
        maxId = roleId;
      }
    }
    const lastId = this.roles[this.roles.length - 1].id;
    const vals = lastId.split("_");
    const roleId = `${vals[0]}_${maxId + 1}`;
    this.roles.push({
      id: roleId,
      alias: roleId,
      voiceId: this.voices[0].speakerCode,
      voiceAlias: this.voices[0].label,
    } as RoleData);
    this.save();
  }

  setRoleVoice(role_id: string, voice_id: string) {
    const role = this.roles.find(r => r.id == role_id) as RoleData;
    role.voiceId = voice_id;
    this.subtitles.filter(s => s.roleId == role.id).map(s => this.changeRoleId(s, role.id));
    this.save();
  }

  init(projectId: string) {
    this.loading = true;
    const notification = new ProjectInitNotification(projectId);
    mediator.publish(notification, Events.PROJECT_INIT);
  }

  resoucesReady() {
    if (projectData.video_obj_link != "" && projectData.audio_obj_link != "") {
      this.loading = false;
    }
  }

  translate() {
    if (currSubtitle.sourceLangText == "") {
      currSubtitle.setTargetLangText("");
      return;
    }
    const notification = new TranslateSubtitleNotification(currSubtitle.sourceLangText);
    mediator.publish(notification, Events.TRANSLATE_SUBTITLE);
  }
  
  replace(text: string, replacement: string) {
    const notification = new ReplaceTextNotification(text, replacement);
    mediator.publish(notification, Events.REPLACE_TEXT);
  }

  replaceText(text: string, replacement: string) {
    this.subtitles.forEach(s => {
      const new_source = s.sourceLangText.replace(text, replacement);
      const new_target = s.targetLangText.replace(text, replacement);
      if (!(s.sourceLangText == new_source && s.targetLangText == new_target)) {
        s.audio = "";
      }
      s.sourceLangText = new_source;
      s.targetLangText = new_target;
      s.characterCount = s.sourceLangText.length;
      s.voiceSpeed = calcTargetLanguageReadingSpeed(s.targetLangText, s.timelineCursor.duration);
    });
    this.save();
  }

  previewSubtitle(sectionId: string) {
    const notification = new PreviewSubtitleNotification(sectionId);
    mediator.publish(notification, Events.PREVIEW_SUBTITLE);
  }

  insertSubtitle(previous: boolean) {
    const notification = new InsertSubtitleNotification(previous);
    mediator.publish(notification, Events.INSERT_SUBTITLE);
  }

  /**
   * merge functions
   */

  setMergeRange(subtitleIds: string[]) {
    this.mergeSubtitleIds = subtitleIds;
  }

  mergeSubtitle() {
    const notification = new MergeSubtitleNotification(this.mergeSubtitleIds);
    mediator.publish(notification, Events.MERGE_SUBTITLE);
  }

  splitSubtitle(cursor: number) {
    const notification = new SplitSubtitleNotification(cursor);
    mediator.publish(notification, Events.SPLIT_SUBTITLE);
  }

  deleteSubtitle() {
    const notification = new DeleteSubtitleNotification();
    mediator.publish(notification, Events.DELETE_SUBTITLE);
  }

  downloadAudio() {
    const notification = new DownloadAudioNotification();
    mediator.publish(notification, Events.DOWNLOAD_AUDIO);
  }

  applySensitiveWords(sensitiveWords: any[]) {
    sensitiveWords.forEach(word => {
      if (word.keywords && word.replace && word.keywords.length > 0) {
        this.subtitles.forEach(s => {
          const new_source = s.sourceLangText.replace(word.keywords, word.replace);
          const new_target = s.targetLangText.replace(word.keywords, word.replace);
          if (!(s.sourceLangText == new_source && s.targetLangText == new_target)) {
            s.audio = "";
          }
          s.sourceLangText = new_source;
          s.targetLangText = new_target;
          s.characterCount = s.sourceLangText.length;
          s.voiceSpeed = calcTargetLanguageReadingSpeed(s.targetLangText, s.timelineCursor.duration);
        });
      }
    });
    this.save();
  }

  setSourcetLangText(id: string, text: string) {
    const record = this.subtitles.find(s => s.sectionId == id) as SubtitleData;
    record.sourceLangText = text;
  }

  setTargetLangText(id: string, text: string) {
    const record = this.subtitles.find(s => s.sectionId == id) as SubtitleData;
    if (record.targetLangText != text) {
      record.audio = "";
    }
    record.targetLangText = text;
    record.voiceSpeed = calcTargetLanguageReadingSpeed(record.targetLangText, record.timelineCursor.duration);
  }

  setVoiceSpeed(id: string, voiceSpeed: number) {
    const record = this.subtitles.find(s => s.sectionId == id) as SubtitleData;
    if (record.voiceSpeed != voiceSpeed) {
      record.audio = "";
    }
    record.voiceSpeed = voiceSpeed;
  }

  getTimelineRange(id: string) {
    const previous = this.getPreviousSubtitle(id);
    const next = this.getNextSubtitle(id);
    let left = 0;
    let right = directData.duration;
    if (previous != null) {
      left = toSeconds(previous.timelineCursor.endAt);
    }
    if (next != null) {
      right = toSeconds(next.timelineCursor.startAt);
    }
    return [left, right];
  }

  setTimelineStart(id: string, start: string) {
    const startNum = toSeconds(start);
    if (startNum == -1) {
      return false;
    }

    const record = this.subtitles.find(s => s.sectionId == id) as SubtitleData;
    const previous = this.getPreviousSubtitle(id);
    if ((previous != null && startNum < toSeconds(previous.timelineCursor.endAt)) || startNum >= toSeconds(record.timelineCursor.endAt)) {
      return false;
    }


    if (toSeconds(record.timelineCursor.startAt) != startNum) {
      record.audio = "";
      record.originalAudio = "";
    }
    record.timelineCursor.startAt = toSrtTimeString(startNum);
    record.timelineCursor.duration = Math.round((toSeconds(record.timelineCursor.endAt) - startNum) * 1000);
    record.voiceSpeed = calcTargetLanguageReadingSpeed(record.targetLangText, record.timelineCursor.duration);
    return true;
  }

  setTimelineEnd(id: string, end: string) {
    const endNum = toSeconds(end);
    if (endNum == -1) {
      return false;
    }

    const record = this.subtitles.find(s => s.sectionId == id) as SubtitleData;
    const next = this.getNextSubtitle(id);
    if ((next != null && endNum > toSeconds(next.timelineCursor.startAt)) || endNum < toSeconds(record.timelineCursor.startAt)) {
      return false;
    }


    if (toSeconds(record.timelineCursor.endAt) != endNum) {
      record.audio = "";
      record.originalAudio = "";
    }
    record.timelineCursor.endAt = toSrtTimeString(endNum);
    record.timelineCursor.duration = Math.round((endNum - toSeconds(record.timelineCursor.startAt)) * 1000);
    record.voiceSpeed = calcTargetLanguageReadingSpeed(record.targetLangText, record.timelineCursor.duration);
    return true;
  }

  setRoleId(id: string, roleId: string) {
    const record = this.subtitles.find(s => s.sectionId == id) as SubtitleData;
    this.changeRoleId(record, roleId);    
    this.save();
  }

  changeRoleId(record: SubtitleData, roleId: string) {
    record.roleId = roleId;
    const role = projectData.roles.find((r) => r.id == roleId);
    const voice = projectData.voices.find((v) => v.speakerCode == role?.voiceId);

    record.emotions = voice?.emotions as EmotionData[];
    record.emotionSupported = voice?.emotionSupported as boolean;
    record.emotionValue = record.emotionSupported ? record.emotions[0].value : null;
    record.emotionLabel = record.emotionValue ? (voice?.emotionSupported ? (voice.emotions.find((e) => e.value ==  record.emotionValue) as any).label : DEAFULT_EMOTION) : DEAFULT_EMOTION
    record.audio = "";
  }

  setEmotionValue(id: string, emotionValue: string) {
    const record = this.subtitles.find(s => s.sectionId == id) as SubtitleData;
    record.emotionValue = emotionValue;
    record.audio = "";
    this.save();
  }

  setRoleAlias(roleId: string, alias: string) {
    const record = this.roles.find(s => s.id == roleId) as RoleData;
    record.alias = alias;
  }

  setSubtitles(data: SubtitleData[]) {
    this.subtitles = data;
  }

  getSubtitle(id: string) {
    return this.subtitles.find(s => s.sectionId == id);
  }

  getPreviousSubtitle(id: string) {
    const curIndex = this.subtitles.findIndex(s => s.sectionId == id);
    if (curIndex - 1 >= 0)
      return this.subtitles[curIndex - 1]
    return null;
  }

  getNextSubtitle(id: string) {
    const curIndex = this.subtitles.findIndex(s => s.sectionId == id);
    if (curIndex + 1 < this.subtitles.length)
      return this.subtitles[curIndex + 1]
    return null;
  }

  async save(isResult = false) {
    if (!isResult) {
      this.generated_audio_obj_link = "";
    }
    
    const snapshot = {
      name: this.name,
      version: this.version,
      source_lang: this.config.sourceLang,
      target_lang: this.config.targetLang,
      roles: this.roles.map(role => { return {
        role_id: role.id,
        alias: role.alias,
        voice_id: role.voiceId,
        voice_alias: role.voiceAlias
      } as Role; }),
      subtitles: this.subtitles.map(subtitle => { return {
        section_id: subtitle.sectionId,
        source_lang_text: subtitle.sourceLangText,
        target_lang_text: subtitle.targetLangText,
        role_id: subtitle.roleId,
        order: subtitle.order,
        voice_speed: subtitle.voiceSpeed,
        timeline_cursor: {
          started_at: subtitle.timelineCursor.startAt,
          ended_at: subtitle.timelineCursor.endAt,
          duration: subtitle.timelineCursor.duration
        },
        emotion_value: subtitle.emotionValue,
        original_audio: subtitle.originalAudio,
        character_count: subtitle.characterCount,
        gen_audio: subtitle.audio
      } as Subtitle;}), // 字幕配置
      sensitive_word_settings: this.sensitive_word_settings,
      generated_audio_obj_link: this.generated_audio_obj_link
    } as ProjectSnapshot;
    
    await saveSnapshot(this.id, snapshot);
  }
}

export const projectData = new ProjectData();
