import service from "./service";
import userStore from "@/stores/user";

export enum RESOURCE_TYPE {
  RAW_VIDEO_UPLOAD = "RAW_VIDEO_UPLOAD",
  RAW_AUDIO_UPLOAD = "RAW_AUDIO_UPLOAD",
  RAW_TEXT_UPLOAD = "RAW_TEXT_UPLOAD",
  AUDIO_ARTIFACT_UPLOAD = "AUDIO_ARTIFACT_UPLOAD",
  SUBTITLE_ARTIFACT_UPLOAD = "SUBTITLE_ARTIFACT_UPLOAD",
  RAW_AUDIO_FRAG_UPLOAD = "RAW_AUDIO_FRAG_UPLOAD"
}

export const previewUploadUrl = (type: RESOURCE_TYPE, projectId = "1", sectionId = "") => {
  const teamId = userStore.info?.teamId;
  const query = sectionId != "" ? `?section_id=${sectionId}` : "";
  return service.get(`/apis/app.aivt.digitalnotee.api/projects/v1alpha1/${teamId}/${projectId}/oss-perms/${type}${query}`).then((data) => {
    return Promise.resolve(data.data);
  });
};
