import { RouteProps } from 'react-router-dom';
import ProjectPage from '@/pages/project/index';
import LoginPage from '@/pages/login';
import TeamFilesPage from '@/pages/team-files'
import TeamManagementPage from '@/pages/team-management';
import NotificationPage from '@/pages/notification';
import RechargePage from '@/pages/recharge';
import CentrePage from '@/pages/centre';
import WorkbenchPage from '@/pages/workbench';

export interface RouteOptions extends RouteProps {
  routes?: RouteOptions[];
  auth?: boolean;
  redirect?: string;
}

export const URL = {
  home: '/',
  workbench: '/workbench',
  teamFiles: '/teamfiles',
  teamManagement: '/teammanagement',
  notification: '/notification',
  recharge: '/recharge',
  centre: '/centre',
  login: '/login',
  project: '/project/:projectId'
};

export const routes: RouteOptions[] = [
  {
    path: URL.workbench,
    component: WorkbenchPage, // 拿token，然后自动跳转
    auth: true
    // redirect: '/edit/1',
  },
  {
    path: URL.teamFiles,
    component: TeamFilesPage,
    auth: true
  },
  {
    path: URL.teamManagement,
    component: TeamManagementPage,
    auth: true
  },
  {
    path: URL.notification,
    component: NotificationPage,
    auth: true
  },
  {
    path: URL.recharge,
    component: RechargePage,
    auth: true
  },
  {
    path: URL.centre,
    component: CentrePage,
    auth: true
  },
  {
    path: URL.project,
    component: ProjectPage,
    auth: true
  },
  {
    path: URL.home,
    component: LoginPage,
    exact: true,
  },
];