import { observer } from 'mobx-react-lite';
import s from './style.module.css';
import Draggable from 'react-draggable';
import currSubtitle, { ShowType } from '@/stores/data/curr-subtitle';
import { useRef, useState } from 'react';

const SubtitleTrack: React.FC<any> = observer((props: { }) => {
  const preventDefault = (e: any) => e.preventDefault();
  const nodeRef = useRef(null);

  return (
    <Draggable nodeRef={nodeRef} bounds="parent"  defaultPosition={{x: 0, y: -25}} > 
      <span ref={nodeRef} className={s.container} onClick={preventDefault}> 
        <div className={s.contentWrap} onClick={preventDefault}>
          {(currSubtitle.showSubttileMode == ShowType.SourceAndTarget || currSubtitle.showSubttileMode == ShowType.SourrcOnly) && <div className={s.subtitle} onClick={preventDefault}>{currSubtitle.sourceLangText}</div>}
          {(currSubtitle.showSubttileMode == ShowType.SourceAndTarget || currSubtitle.showSubttileMode == ShowType.TragetOnly) && <div className={s.subtitle} onClick={preventDefault}>{currSubtitle.targetLangText}</div>}
        </div>
      </span>
    </Draggable>
  );
});

export default SubtitleTrack;
