import { INotificationHandler, mediator, NotificationHandler } from '@/stores/mediator-system';
import { Material, materialManager, MaterialType } from '@/stores/material-manager/material-manager';
import { createFFmpeg, FFmpeg } from '@ffmpeg/ffmpeg';
import PriorityQueue from 'p-queue/dist/priority-queue';
import PQueue, { QueueAddOptions } from 'p-queue/dist';
import { toSeconds, Unit } from '@/utils/time';
import { computed, makeObservable, observable } from 'mobx';
import { IndicatorChangedNotification } from '../notification/directEngine';
import { DirectEngineStatus } from '../type/directEngine';
import { getLogger, Scope } from '@/stores/log/log';
import { id } from '@/utils/id';
import message from 'antd/lib/message';
import { directData } from '../data/direct-data';
import { projectData } from '../data/project-data';
import { start } from 'repl';
import currSubtitle from '../data/curr-subtitle';
import { SubtitleData } from '../model/project';
import { downloadFile } from '@/utils/file';
import { RESOURCE_TYPE } from '@/axios/storage';
import { uploadFile } from '@/storage';

@NotificationHandler('IndicatorChangedNotification', 'IndicatorChangedNotificationHandler', 0)
export class IndicatorChangedNotificationHandler implements INotificationHandler<IndicatorChangedNotification> {
  handle(notification: IndicatorChangedNotification): Promise<void> {
    directingEngine.handleIndicatorChanged(notification);
    return Promise.resolve(undefined);
  }
}

export class DirectEngine {
  /**
   * 当前导演引擎状态
   */
  state = DirectEngineStatus.PAUSED;
  /**
   * 异步导演调度函数标识符
   */
  private background!: number;
  /**
   * 资源管理器
   */
  private readonly materialManager = materialManager;
  /**
   * FFmpeg 句柄
   */
  private readonly ffmpeg!: FFmpeg;
  /**
   * Promise 队列
   */
  private readonly promiseQueue: PQueue;
  /**
   * 用户第一次点击播放的时间
   */
  private startedAt = 0;
  /**
   * 指针时间
   */
  private indicatorTime = 0;
  private performanceTime = 0;
  public subtitleIdx = 0;
  private readonly logger = getLogger(Scope.DirectEngine);

  isPreviewing = false;
  private lastIndicator = 0;
  private audioUrl = "";
  private audio: any;

  /**
   * 播放截止位置
   */
  private playDuration = 0;

  constructor() {
    this.ffmpeg = createFFmpeg({
      corePath: '/wasm/ffmpeg-core.js',
      wasmPath: '/wasm/ffmpeg-core.wasm',
      workerPath: '/wasm/ffmpeg-core.worker.js',
      log: true,
    });
    this.promiseQueue = new PQueue<PriorityQueue, QueueAddOptions>({
      concurrency: 1,
    });

    makeObservable(this, {
      state: observable,
      isPlaying: computed,
    });
  }

  public get isPlaying(): boolean {
    return this.state !== DirectEngineStatus.PAUSED;
  }

  /**
   * 导演函数，获取当前内部时钟，根据内部时钟判断是否存在需要进入舞台的片段和退出舞台的片段
   */
  public directing(): void {
    if (this.indicatorTime >= this.playDuration || this.indicatorTime >= directData.getDuration()) {
      window.cancelAnimationFrame(this.background);
      this.pause();
      if (this.isPreviewing) {
        directData.setCurrent(this.lastIndicator);
      }
      return;
    }

    if (this.state === DirectEngineStatus.PLAYING) {
      this.background = window.requestAnimationFrame(this.directing.bind(this));
    }

    this.indicatorTime = this.performanceTime + (performance.now() - this.startedAt) / 1000;
    directData.setCurrent(this.indicatorTime);
    if (!this.isPreviewing || projectData.previewAll) this.handleSubtitle();
  }

  private handleSubtitle() {
    if (this.subtitleIdx < projectData.subtitles.length) {
      const { startAt, endAt } = projectData.subtitles[this.subtitleIdx].timelineCursor;
      const start = toSeconds(startAt);
      const end = toSeconds(endAt);
      if (this.indicatorTime >= start && this.indicatorTime < end) {
        currSubtitle.set(projectData.subtitles[this.subtitleIdx]);
      } else if (this.indicatorTime > end) {
        currSubtitle.set();
        this.subtitleIdx++;
      } else {
        currSubtitle.set();
      }
    }
  }

  public handleIndicatorChanged(notification: IndicatorChangedNotification) {
    if (!notification.manually) {
      return;
    }
    if (this.isPlaying) {
      this.pause();
    }
    this.indicatorTime = notification.current;
  }

  public getSubtitleIndexByCurrent() {
    let idx = 0;
    const current = directData.getCurrent();
    while(idx < projectData.subtitles.length) {
      const end = toSeconds(projectData.subtitles[idx].timelineCursor.endAt);
      if (current < end) {
        return idx;
      }
      idx++;
    }
    return idx;
  }

  public canPlay() {
    if (directData.current >= this.playDuration) {
      return false;
    }
    return true;
  }

  /**
   * 播放
   */
  public async play(): Promise<boolean> {
    if (this.state === DirectEngineStatus.PLAYING) {
      await this.pause();
      return Promise.resolve(false);
    }
    this.indicatorTime = directData.getCurrent();
    this.playDuration = directData.getDuration();

    if (!this.canPlay()) {
      message.warning('超出最大播放时间，视频将不会播放。');
      await this.pause();
      return false;
    }
    this.isPreviewing = false;

    this.state = DirectEngineStatus.PLAYING;
    this.startedAt = performance.now(); // 重置开始播放的时间点
    this.performanceTime = this.indicatorTime;

    this.seekCurrentSubtitle();
    this.directing();
    return Promise.resolve(true);
  }

  /**
   * 预览
   */
  public async preview(start: number, url: string): Promise<boolean> {
    if (this.state === DirectEngineStatus.PLAYING) {
      await this.pause();
      return Promise.resolve(false);
    }
    directData.setCurrent(start);
    this.indicatorTime = start;
    this.lastIndicator = start;

    this.audio = new Audio(url);
    this.audio.crossOrigin = "anonymous";

    this.audio.addEventListener("loadedmetadata", () => {
      console.log("load audio finished.");
      if (projectData.previewAll) this.audio.currentTime = directData.current;
      this.playDuration = this.indicatorTime + this.audio.duration;
      this.state = DirectEngineStatus.PLAYING;
      this.isPreviewing = true;
      this.startedAt = performance.now(); // 重置开始播放的时间点
      this.performanceTime = this.indicatorTime;
      this.audio.play();
      
      this.seekCurrentSubtitle();
      this.directing();
      
    });
    this.audio.load();
    return Promise.resolve(true);
  }

  private seekCurrentSubtitle() {
    this.subtitleIdx = this.getSubtitleIndexByCurrent();
    this.handleSubtitle();
  }

  /**
   * 暂停
   */
  public async pause(): Promise<void> {
    if (this.state === DirectEngineStatus.PAUSED) {
      return;
    }

    this.state = DirectEngineStatus.PAUSED;

    // 停止导演调度程序
    window.cancelAnimationFrame(this.background);
    if (this.isPreviewing) {
      this.audio.pause();
      directData.setCurrent(this.lastIndicator);
      this.isPreviewing = false;
    }
  }

  private getCurrentTime(): number {
    return this.indicatorTime;
  }

  /**
   * 执行从视频片段中分离为音频轨和视频轨
   * @param segment 需要执行操作的视频轨
   */
  public async VideoSeparation(name: string, file: File): Promise<any> {
   
    if (!this.ffmpeg.isLoaded()) await this.ffmpeg.load();
    console.log("音画分离开始");
    const filename = file.name;

    // 挂载到FFmpeg文件系统中
    this.ffmpeg.FS('writeFile', <string>filename, new Uint8Array(await file.arrayBuffer()));

    // 使用FFmpeg进行音频提取(16000Hz), 单声道
    await this.ffmpeg.run('-i', <string>filename, '-ac', '1', '-ar', '16000', '-vn', name + '.wav');

    // 从FFmpeg文件系统中读取分离出的音频文件数据
    let audioBuf: Uint8Array;
    try {
      audioBuf = this.ffmpeg.FS('readFile', name + '.wav');
      this.ffmpeg.FS('unlink', <string>name + '.wav');
      this.ffmpeg.FS('unlink', <string>filename);
    } catch (e) {
      return Promise.reject(
        `对视频片段"${filename}"执行音轨分离操作出现错误，尝试读取分离后音频文件数据失败`,
      );
    }

    const now = new Date(); // 获取当前时间
    const timestampMs = now.getTime(); // 获取以毫秒为单位的时间戳
    const audioName = name;

    // 设置上传到OSS对象存储对象的名称
    const objectName = `${audioName}_${timestampMs}${id()}.wav`;
    const fileopt = new File([audioBuf], objectName);
    return Promise.resolve(fileopt);
  }

  public async AudioClip(name: string, fileBlob: ArrayBuffer, start: number, duration: number): Promise<any> {
   
    if (!this.ffmpeg.isLoaded()) await this.ffmpeg.load();
    const filename = name;

    this.ffmpeg.FS('writeFile', <string>filename, new Uint8Array(fileBlob));

    // 根据时间轨分离音频
    await this.ffmpeg.run('-i', <string>filename, '-ss', start.toString(), '-t', duration.toString(), name + '.wav');

    // 从FFmpeg文件系统中读取分离出的音频文件数据
    let audioBuf: Uint8Array;
    try {
      audioBuf = this.ffmpeg.FS('readFile', name + '.wav');
      this.ffmpeg.FS('unlink', <string>name + '.wav');
      this.ffmpeg.FS('unlink', <string>filename);
    } catch (e) {
      return Promise.reject(
        `对视频片段"${filename}"执行音轨分离操作出现错误，尝试读取分离后音频文件数据失败`,
      );
    }

    const now = new Date(); // 获取当前时间
    const timestampMs = now.getTime(); // 获取以毫秒为单位的时间戳
    const audioName = name;

    // 设置上传到OSS对象存储对象的名称
    const objectName = `${audioName}_${timestampMs}${id()}.wav`;
    const fileopt = new File([audioBuf], objectName);
    return Promise.resolve(fileopt);
  }

  public async checkMergeResource(material: Material) {
    const section = projectData.getSubtitle(material.sectionId as string) as SubtitleData;
    section.audioMaterial = material.blob as ArrayBuffer;
    if (projectData.subtitles.filter(s => s.audioMaterial == undefined).length < 1 && projectData.bgMaterial != undefined) {
      console.log(projectData.subtitles)
      console.log("can merge now")
      directingEngine.MergeAudio();
    } else {
      console.log("download continue");
    }
  }

  public async checkMergeBg(material: Material) {
    projectData.bgMaterial = material.blob;

    if (projectData.subtitles.filter(s => s.audioMaterial == undefined).length < 1 && projectData.bgMaterial != undefined) {
      console.log(projectData.subtitles)
      console.log("can merge now")
      directingEngine.MergeAudio();
    } else {
      console.log("download continue");
    }
  }

  public async MergeAudio(): Promise<any> {
    if (!this.ffmpeg.isLoaded()) await this.ffmpeg.load();
    console.log("音频合成开始");
    const bgName = "bg.wav";
    const bgNameMuted = "bg.muted.wav";
    this.ffmpeg.FS('writeFile', <string>bgName, new Uint8Array(projectData.bgMaterial as ArrayBuffer));

    const args = ["-i", bgNameMuted];
    const args_muted = ["-i", bgName];

    let filterComplex = "";
    let mix = "[0]";
    // 挂载到FFmpeg文件系统中
    const mute = [];

    // 开始向前偏移200 ms
    const DEFAULT_OFFSET = 200 / 1000;

    let index = 1;

    let last_end = 0;
    for (const section of projectData.subtitles) {
      if (section.targetLangText == "") continue;
      const filename = `${section.sectionId}.wav`;
      this.ffmpeg.FS('writeFile', <string>filename, new Uint8Array(section.audioMaterial as ArrayBuffer));
      args.push("-i");
      args.push(filename);
      filterComplex += `[${index}]adelay=${toSeconds(section.timelineCursor.startAt) * 1000}[a${index}]; `;
      mix += `[a${index}]`;

      let startAt = toSeconds(section.timelineCursor.startAt);
      if (startAt - DEFAULT_OFFSET < last_end) {
        startAt = last_end;
      } else {
        startAt -= DEFAULT_OFFSET;
      }
      
      mute.push(`volume=enable='between(t,${startAt},${toSeconds(section.timelineCursor.endAt)})':volume=0`)

      last_end = toSeconds(section.timelineCursor.endAt);
      index++;
    }

    args_muted.push("-af");
    args_muted.push(mute.join(", "))
    args_muted.push(bgNameMuted);
    await this.ffmpeg.run(...args_muted);

    args.push("-filter_complex");
    args.push(`${filterComplex}${mix}amix=${index}:dropout_transition=1000,volume=${index}`)

    const name = "output";
    args.push(name + '.wav');

    console.log(args);

    await this.ffmpeg.run(...args);

    let audioBuf: Uint8Array;
    try {
      audioBuf = this.ffmpeg.FS('readFile', name + '.wav');
      this.ffmpeg.FS('unlink', <string>name + '.wav');
      this.ffmpeg.FS('unlink', <string>bgName);
      for (const section of projectData.subtitles) {
        if (section.targetLangText == "") continue;
        const filename = `${section.sectionId}.wav`;
        this.ffmpeg.FS('unlink', <string>filename);
      }
    } catch (e) {
      return Promise.reject(
        `合成视频失败。`,
      );
    }

    const now = new Date(); // 获取当前时间
    const timestampMs = now.getTime(); // 获取以毫秒为单位的时间戳
    const audioName = name;

    const objectName = `${audioName}_${timestampMs}${id()}.wav`;
    const fileopt = new File([audioBuf], objectName);

    if (!projectData.previewAll) {
      downloadFile(fileopt);
      projectData.loading = false;
    } else {
      projectData.loading = false;
      const url = window.URL.createObjectURL(fileopt);
      directingEngine.preview(directData.current, url);
    }

    const callbacks = {
      onProgress: (e: any) => console.log(e),
      onSuccess: (e: any) => {
        console.log(e);
      }, 
      onError: (e: any) => console.log(e) } as any;
    const url = await uploadFile(fileopt, RESOURCE_TYPE.AUDIO_ARTIFACT_UPLOAD, callbacks, projectData.id);    
    projectData.generated_audio_obj_link = url;
    projectData.save(true);
    return Promise.resolve(fileopt);
  }
}

const directingEngine = new DirectEngine();
(window as any).directingEngine = directingEngine;
export default directingEngine;
