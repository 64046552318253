import { EmotionInfo, ProjectPayload, Role, SpeakerInfo, Subtitle, TimelineCursor } from "@/axios/project";
import { makeAutoObservable } from "mobx";
import { projectData } from "../data/project-data";

export class ProjectConfigData {
  sourceLang: string; // 源语言
  targetLang: string; // 目标语言

  constructor(source_lang: string, target_lang: string) {
    this.sourceLang = source_lang;
    this.targetLang = target_lang;
    makeAutoObservable(this);
  }
}

export class RoleData {
  id: string; // 角色编号
  alias: string; // 角色别名
  voiceId: string; // 声音编号
  voiceAlias: string; // 声音别名

  constructor(obj: Role) {
    const { role_id, alias, voice_id, voice_alias } = obj;
    this.id = role_id;
    this.alias = alias;
    this.voiceId = voice_id;
    this.voiceAlias = voice_alias;
    makeAutoObservable(this);
  }
}

export class EmotionData {
  label: string;
  value: string;

  constructor(obj: EmotionInfo) {
    const { label, value } = obj;
    this.label = label;
    this.value = value;
    makeAutoObservable(this);
  }
}
const DEAFULT_EMOTION = "默认情感";

export class SubtitleData {
  sectionId: string; // 字幕编号（唯一）
  roleId: string; // 对应角色编号
  timelineCursor: TimelineCursorData; // 时间范围 
  order: number; // 排序
  sourceLangText: string; // 源语言文本
  targetLangText: string; // 目标语言文本
  characterCount: number; // 字符数
  voiceSpeed: number; // 读速
  originalAudio: string; //裁切出的原始音频
  audio: string; // 生成的目标音频
  audioMaterial: ArrayBuffer | undefined; //音频缓存
  emotionSupported: boolean;
  emotionValue: string | null; //情感
  emotionLabel: string;
  emotions: EmotionData[];

  constructor(obj: Subtitle, roles: RoleData[], voices: SpeakerInfo[]) {
    const { section_id, role_id, timeline_cursor, order, source_lang_text, target_lang_text, character_count, voice_speed, gen_audio, original_audio, emotion_value } = obj;
    this.sectionId = section_id;
    this.roleId = role_id;
    this.timelineCursor = new TimelineCursorData(timeline_cursor);
    this.order = order;
    this.sourceLangText = source_lang_text;
    this.targetLangText = target_lang_text;
    this.characterCount = character_count;
    this.voiceSpeed = Number(voice_speed.toFixed(1));
    this.audio = gen_audio;
    this.audioMaterial = undefined;

    const role = roles.find((r) => r.id == role_id);
    const voice = voices.find((v) => v.speakerCode == role?.voiceId);

    this.emotions = voice?.emotions as EmotionData[];
    this.emotionSupported = voice?.emotionSupported as boolean;
    this.emotionValue = emotion_value;
    this.emotionLabel = this.emotionValue ? (voice?.emotionSupported ? (voice.emotions.find((e) => e.value ==  this.emotionValue) as any).label : DEAFULT_EMOTION) : DEAFULT_EMOTION
    this.originalAudio = original_audio;
    makeAutoObservable(this);
  }
}

export class TimelineCursorData {
  startAt: string; // 开始时间
  endAt: string; // 结束时间
  duration: number; // 片段长度

  constructor(obj: TimelineCursor) {
    const { started_at, ended_at, duration } = obj;
    this.startAt = started_at;
    this.endAt = ended_at;
    this.duration = duration;
    makeAutoObservable(this);
  }
}
