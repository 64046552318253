import { URL } from '@/routes';
import { customHistory } from '@/routes/RootRouter';
import { default as user, default as userStore } from '@/stores/user';
import axios from 'axios';
import { getTokenByRefreshToken } from './user';

export interface ApiResponse<T> {
  code: number;
  data: T;
  msg: string;
}

export const TENANT_ID = 1;

// const getHost = () => {
//   if (process.env.NODE_ENV === 'development') {
//     return 'http://wx-miniapp.nps.refeiner.com';
//   }
//   if (process.env.NODE_ENV === 'production') {
//     return 'https://api.test.dn-ai-vt.elightcloud.com';
//   }

//   return 'https://dub.youdao.com';
// }

const token = getToken();
if (token) {
  user.setInfo({ accessToken: token });
} else if (location.pathname !== '/') {
  location.href = `${location.origin}/`;
}

export function getToken() {
  if (location.search.startsWith('?tt=')) {
    return location.search.replace('?tt=', '');
  }
  return localStorage.getItem('user-token');
}

export function getRefreshToken() {
  return localStorage.getItem('user-refresh-token');
}

const service = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Cross-Origin-Resource-Policy': 'cross-origin',
    'Tenant-Id': TENANT_ID,
    // 'Authorization': localStorage.getItem('user-token')
  },
});

service.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    // if (userStore.info?.accessToken) {
    //   // config.headers.Authorization = `${userStore.info.accessToken}`;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  async (response) => {
    if (response.data.code === 602) {
      // 跳去首页拿token
      customHistory.push('/');
      return Promise.reject('登录过期');
    } else if (response.data.code === 401) {
      const originalRequest = response.config;
      if (originalRequest.url?.includes("authenticate/renew")) {
        return Promise.reject('登录过期');
      } else {
        return retry(originalRequest);
      }
    }
    return response.data;
  },
  async (error) => {
    console.log("axios error: " + error);
    const originalRequest = error.config;

    if (error.response?.status === 401 && userStore.info?.refreshToken) {
      return retry(originalRequest);
    } else {
      userStore.setInfo(null);
      customHistory.push(URL.home);
    }

    return Promise.reject(error);
  },
);

async function retry(originalRequest: any): Promise<any> {
  try {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      customHistory.push('/');
      return Promise.reject('登录过期');
    }
    const res = await getTokenByRefreshToken(refreshToken);
    console.log(res)
    if (res.code != 0) {
      customHistory.push('/');
      return Promise.reject('登录过期');
    }
    
    userStore.setInfo({
      token: res.data.access_token,
      teamId: res.data.team_id, 
      refreshToken: res.data.refresh_token,
    });
    localStorage.setItem('user-token', res.data.access_token);
    localStorage.setItem('user-refresh-token', res.data.refresh_token);

    originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
    return service(originalRequest)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  } catch {
    userStore.setInfo(null);
    customHistory.push(URL.home);
  }
}

export default service;
