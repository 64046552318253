import { toHMS } from "@/utils/time";
import { makeAutoObservable } from "mobx";

class DirectData {
  /**
   * 当前时间(秒)
   */
  current = 0;
  duration = 0;

  constructor() {
      makeAutoObservable(this);
  }

  setCurrent(val: number) {
    this.current = val;
  }

  getCurrent() {
    return this.current;
  }

  setDuration(val: number) {
    this.duration = val;
  }

  getDuration() {
    return this.duration;
  }

  /**
   *  获取当前播放时间（时分秒）
   * @returns 当前时间（时分秒）
   */
  getCurrentHMS() {
    return toHMS(this.current * 1000);
  }

  getDurationHMS() {
    return toHMS(this.duration * 1000);
  }
}

export const directData = new DirectData();
