import { INotification } from "../mediator-system/notification";

export class ProjectInitNotification implements INotification {
  id!: string; // Project ID

  constructor(id: string) {
      this.id = id;
  }
}

export class PreviewSubtitleNotification implements INotification {
  sectionId: string; // SECTION ID

  constructor(sectionId: string) {
      this.sectionId = sectionId;
  }
}

export class TranslateSubtitleNotification implements INotification {
  text: string;

  constructor(text: string) {
    this.text = text;
  }
}

export class InsertSubtitleNotification implements INotification {
  insertPrevious?: boolean;

  constructor(insertPrevious?: boolean) {
    this.insertPrevious = insertPrevious;
  }
}

export class InsertRoleNotification implements INotification {}

export class UpdateSubtitleNotification implements INotification {
  sourceLangText?: string;
  targetLangText?: string;
  roleId?: string;

  constructor(sourceLangText?: string, targetLangText?: string, roleId?: string) {
    this.sourceLangText = sourceLangText;
    this.targetLangText = targetLangText;
    this.roleId = roleId;
  }
}

export class UpdateRoleNotification implements INotification {
  roleId: string;
  roleAlias?: string;
  voiceId?: string;

  constructor(roleId: string, roleAlias?: string, voiceId?: string) {
    this.roleId = roleId;
    this.roleAlias = roleAlias;
    this.voiceId = voiceId;
  }
}

export class ReplaceTextNotification implements INotification {
  text: string;
  replacement: string;

  constructor(text: string, replacement: string) {
    this.text = text;
    this.replacement = replacement;
  }
}

export class MergeSubtitleNotification implements INotification {
  ids: string[];

  constructor(ids: string[]) {
    this.ids = ids;
  }
}

export class SplitSubtitleNotification implements INotification {
  cursor: number;

  constructor(cursor: number) {
    this.cursor = cursor;
  }
}

export class DeleteSubtitleNotification implements INotification {}

export class DownloadSrtNotification implements INotification {}

export class DownloadAudioNotification implements INotification {}
