import { registry } from '@/stores/mediator-system/registry';
import { dispatcher } from '@/stores/mediator-system/dispatcher';
import { DispatchInstance } from '@/stores/mediator-system/dispatch-instance';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotification {}

export interface INotificationHandler<N> {
  handle(notification: N): Promise<void>;
}

const NotificationHandler = (event: string, handlerName: string, order?: number) => {
  return (target: Function): void => {
    // const eventName = (value as Function).prototype.constructor.name;
    // const handlerName = (target as Function).prototype.constructor.name;
    registry.register(handlerName, target);
    dispatcher.register(new DispatchInstance(event, order || 0, handlerName));
  };
};

export default NotificationHandler;
