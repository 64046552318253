import React, { FC, useState, useEffect } from 'react';
import s from './style.module.css';

export interface IPlayerTimeLineProps {
  current: number; // 单位秒
  total: number;
  isPause: boolean;
  totalWidth?: number;
  disabled?: boolean;
  alwaysShowHandle?: boolean;
  onChange: (current: number) => void;
}

const TOTAL_WIDTH = 648;
const HANDLE_OFFSET = 6;

const PlayerTimeLine: FC<IPlayerTimeLineProps> = ({
  current: _current,
  total,
  isPause,
  totalWidth = TOTAL_WIDTH,
  disabled,
  alwaysShowHandle,
  onChange,
}) => {
  const current = (() => {
    if (_current < 0) {
      return 0;
    }
    if (_current > total) {
      return total;
    }
    return _current;
  })();
  const percent = current / total;
  const left = totalWidth * percent;

  const [prevUserSelectStyle, setPrevUserSelectStyle] = useState(getComputedStyle(document.body).userSelect);
  const [isMoving, setIsMoving] = useState(false);

  const handleStartMove = (e: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }
    setIsMoving(true);
    setPrevUserSelectStyle(getComputedStyle(document.body).userSelect);
    document.body.style.userSelect = 'none';
  };

  const handleStopMove = () => {
    setIsMoving(false);
    document.body.style.userSelect = prevUserSelectStyle;
  };

  const handleMove = (e: any) => {
    if (!isMoving) {
      return;
    }
    // debugger
    const resLeft = left + e.movementX;
    if (resLeft < 0 || resLeft > totalWidth) {
      return;
    }

    console.log(e)
    let nextCurrent = (resLeft * total) / totalWidth;
    if (nextCurrent < 0.1) {
      // 不容易碰到0的情况
      nextCurrent = 0;
    }
    if (total - nextCurrent < 0.1) {
      nextCurrent = total;
    }
    if (nextCurrent) {
      onChange(nextCurrent); // 新的current
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleStopMove);
    document.addEventListener('mousemove', handleMove);
    return () => {
      document.removeEventListener('mouseup', handleStopMove);
      document.removeEventListener('mousemove', handleMove);
    };
  }, [handleStopMove, handleMove]);

  return (
    <div
      className={s.timeLine}
      style={{
        width: totalWidth,
      }}>
      <div
        className={s.progress}
        style={{
          width: left,
        }}></div>
      {(alwaysShowHandle || isPause) && (
        <div
          className={s.handler}
          onMouseDown={handleStartMove}
          style={{
            left: left - HANDLE_OFFSET,
          }}></div>
      )}
    </div>
  );
};

export default React.memo(PlayerTimeLine);
