import { useState } from 'react';
import s from './style.module.css';
import { Button, Col, Input, Row, message } from 'antd';
import { projectData } from '@/stores/data/project-data';


const ReplaceForm: React.FC<any> = (props: { replace: Function }) => {
  const [text, setText] = useState("");
  const [replacement, setReplacement] = useState("");
  const handleReplace = () => {
    if (text.length > 0) {
      projectData.replace(text, replacement);
    } else {
      message.warning("替换内容为空！");
    }
  }

  return (
    <div className={s.container}>
      <Row className={s.row}>
        <Col className={s.title}>查找内容：</Col>
        <Col flex={1}><Input className={s.input} placeholder="查找内容" value={text} maxLength={100} onChange={e => setText(e.target.value)} /></Col>
      </Row>
      <Row className={s.row}>
        <Col className={s.title}>替换为：</Col>
        <Col flex={1}><Input className={s.input} placeholder="替换为" value={replacement} maxLength={100} onChange={e => setReplacement(e.target.value)} /></Col>
      </Row>
      <Row className={s.right}>
        <Button className={s.actionBtn} onClick={handleReplace}>
          替换
        </Button>
      </Row>
    </div>
  );
};

export default ReplaceForm;