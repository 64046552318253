import { useEffect, useRef, useState } from 'react';
import s from './style.module.css';
import { Input, Popover, Select, Slider, Tooltip, message } from 'antd';
import { useClickAway, useDebounceFn, useSet } from 'ahooks';
import { SubtitleData } from '@/stores/model/project';
import { observer } from 'mobx-react-lite';
import React from 'react';
import currSubtitle from '@/stores/data/curr-subtitle';
import { directData } from '@/stores/data/direct-data';
import { toSeconds, toSrtTimeString } from '@/utils/time';
import directingEngine from '@/stores/directing-engine/direct-engine';
import { projectData } from '@/stores/data/project-data';

const { TextArea } = Input;

const SubtitleTable: React.FC<any> = observer((props: { data: SubtitleData[] }) => {
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [editChs, setEditChs] = useState(false);
  const [editEng, setEditEng] = useState(false);

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [editTimeline, setEditTimeline] = useState(false);

  const [voiceSpeed, setVoiceSpeed] = useState('');
  const [editVoiceSpeed, setEditVoiceSpeed] = useState(false);

  const tableRef = useRef(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const bodyContentRef = useRef<any>(null);
  const chsRef = useRef(null);
  const engRef = useRef(null);
  const voiceSpeedRef = useRef(null);

  const [scrollVisible, setScrollVisible] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver((_) => {
      setScrollVisible(!(bodyRef.current && bodyRef.current.scrollHeight < bodyRef.current.clientHeight));
    });
    if (bodyRef.current) {
      observer.observe(bodyRef.current);
    }
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (chsRef.current != null) (chsRef.current as any).focus();
    if (engRef.current != null) (engRef.current as any).focus();
  }, [selectIds, chsRef.current, engRef.current]);

  useEffect(() => {
    if (directingEngine.isPlaying && currSubtitle.id) {
      setEditChs(false);
      setEditEng(false);
      setEditTimeline(false);
      setEditVoiceSpeed(false);
      trySelectSubtitle(true);
    }
    if (projectData.loading || projectData.merging) {
      setEditChs(false);
      setEditEng(false);
      setEditTimeline(false);
      setEditVoiceSpeed(false);
    } else if (!projectData.merging && selectIds.length > 1) {
      setSelectIds([selectIds[0]]);
    }
  }, [directingEngine.isPlaying, projectData.loading, projectData.merging, currSubtitle.id]);

  useEffect(() => {
    if (!directingEngine.isPlaying) {
      trySelectSubtitle();
    }
  }, [directData.current, directingEngine.isPlaying]);

  const trySelectSubtitle = (isPlaying = false) => {
    const current = directData.getCurrent();
    let height = 0;
    for (const record of props.data) {
      if (current >= toSeconds(record.timelineCursor.startAt) && current < toSeconds(record.timelineCursor.endAt)) {
        setSelectIds([record.sectionId]);
        if (isPlaying) {
          bodyRef.current?.scroll({
            behavior: 'smooth',
            top: height,
          });
        } else if (
          height < (bodyRef.current?.scrollTop as number) ||
          height > (bodyRef.current?.scrollTop as number) + (bodyRef.current?.clientHeight as number)
        ) {
          bodyRef.current?.scroll({
            behavior: 'smooth',
            top: height,
          });
        } else if (
          height + bodyContentRef.current.children[record.order].clientHeight >
          (bodyRef.current?.scrollTop as number) + (bodyRef.current?.clientHeight as number)
        ) {
          bodyRef.current?.scroll({
            behavior: 'smooth',
            top:
              height +
              bodyContentRef.current.children[record.order].clientHeight -
              (bodyRef.current?.clientHeight as number),
          });
        }
        currSubtitle.set(record);
        return;
      }
      height += bodyContentRef.current.children[record.order].clientHeight;
    }
    currSubtitle.set();
    setSelectIds([]);
  };

  const { run: didPreview } = useDebounceFn(
    (e, record: SubtitleData) => {
      projectData.previewSubtitle(record.sectionId);
    },
    {
      wait: 80,
    },
  );

  const handlePreview = (e: MouseEvent, record: SubtitleData) => {
    if (projectData.merging) {
      return;
    }
    e.preventDefault();
    selectRecord(record);
    projectData.previewAll = false;
    didPreview(e, record);
  };

  const handleSelect = (e: MouseEvent, record: SubtitleData) => {
    if (e.defaultPrevented) return;
    if ((e.target as any).className == 'ant-popover-inner-content') return;
    e.preventDefault();
    if (projectData.merging && e.shiftKey && selectIds.length > 0) {
      const firstRecord = projectData.getSubtitle(selectIds[0]) as SubtitleData;
      const lastRecord = projectData.getSubtitle(selectIds[selectIds.length - 1]) as SubtitleData;
      const minOrder = record.order < firstRecord.order ? record.order : firstRecord.order;
      const maxOrder = record.order < firstRecord.order ? lastRecord.order : record.order;
      const ids = projectData.subtitles.filter((s) => s.order >= minOrder && s.order <= maxOrder).map((s) => s.sectionId);
      setSelectIds(ids);
      projectData.setMergeRange(ids);
      return;
    }
    if (editVoiceSpeed) {
      trySaveVoiceSpeed(voiceSpeed);
    }
    selectRecord(record);
    setEditTimeline(false);
    setEditChs(false);
    setEditEng(false);
    setEditVoiceSpeed(false);
  };

  const handleEditChs = (e: MouseEvent, record: SubtitleData) => {
    if (projectData.merging) {
      return;
    }
    e.preventDefault();
    if (editVoiceSpeed) {
      trySaveVoiceSpeed(voiceSpeed);
    }
    selectRecord(record);
    setEditTimeline(false);
    setEditChs(true);
    setEditEng(false);
    setEditVoiceSpeed(false);
  };

  const handleEditEng = (e: MouseEvent, record: SubtitleData) => {
    if (projectData.merging) {
      return;
    }
    e.preventDefault();
    if (editVoiceSpeed) {
      trySaveVoiceSpeed(voiceSpeed);
    }
    selectRecord(record);
    setEditEng(true);
    setEditTimeline(false);
    setEditChs(false);
    setEditVoiceSpeed(false);
  };

  const handleEditVoiceSpeed = (e: MouseEvent, record: SubtitleData) => {
    if (projectData.merging) {
      return;
    }
    e.preventDefault();
    setVoiceSpeed(record.voiceSpeed.toString());
    selectRecord(record);
    setEditVoiceSpeed(true);
    setEditTimeline(false);
    setEditEng(false);
    setEditChs(false);
  };

  const handleEditTimeline = (e: MouseEvent, record: SubtitleData) => {
    if (projectData.merging) {
      return;
    }
    e.preventDefault();
    if (editVoiceSpeed) {
      trySaveVoiceSpeed(voiceSpeed);
    }
    setStart(record.timelineCursor.startAt);
    setEnd(record.timelineCursor.endAt);
    selectRecord(record);
    setEditTimeline(true);
    setEditEng(false);
    setEditChs(false);
    setEditVoiceSpeed(false);
  };

  const selectRecord = (record: SubtitleData) => {
    if (directingEngine.isPlaying) {
      directingEngine.play();
    }
    setSelectIds([record.sectionId]);
    currSubtitle.set(record);
    directData.setCurrent(toSeconds(record.timelineCursor.startAt));
  };

  const cancelSelect = () => {
    if (editVoiceSpeed) {
      trySaveVoiceSpeed(voiceSpeed);
    }
    currSubtitle.set();
    setSelectIds([]);
    setEditChs(false);
    setEditEng(false);
    setEditTimeline(false);
    setEditVoiceSpeed(false);
  };

  const { run: debounceSave } = useDebounceFn(
    () => {
      projectData.save();
      message.success('更新成功!');
    },
    {
      wait: 1000,
    },
  );

  const handleSrcTextChange = (e: any, record: SubtitleData) => {
    currSubtitle.setSourcetLangText(e.target.value);
    debounceSave();
  };

  const handleTargetTextChange = (e: any, record: SubtitleData) => {
    currSubtitle.setTargetLangText(e.target.value);
    debounceSave();
  };

  const { run: didChangeTimeline } = useDebounceFn(
    (e, start: string, end: string) => {
      if (start != '') {
        currSubtitle.setTimelineCursorStart(start);
      }
      if (end != '') {
        currSubtitle.setTimelineCursorEnd(end);
      }
      projectData.save();
    },
    {
      wait: 500,
    },
  );

  const handleTimelineChange = (e: any, record: SubtitleData, isStart: boolean) => {
    e.preventDefault();
    if (isStart) {
      setStart(e.target.value);
      didChangeTimeline(e, e.target.value, '');
    } else {
      setEnd(e.target.value);
      didChangeTimeline(e, '', e.target.value);
    }
  };

  const hanldeSliderChange = (v: any) => {
    const startStr = toSrtTimeString(v[0]);
    const endStr = toSrtTimeString(v[1]);
    setStart(startStr);
    setEnd(endStr);
    didChangeTimeline(null, startStr, endStr);
  };

  const handleVoiceSpeedChange = (e: any, record: SubtitleData) => {
    setVoiceSpeed(e.target.value);
  };

  const handleChsClick = (e: any) => {
    e.preventDefault();
    let cursor = 0;
    if (chsRef.current) {
      cursor = (chsRef.current as any).resizableTextArea?.textArea.selectionStart;
    }
    currSubtitle.setSplitCursor(cursor);
  };

  const trySaveVoiceSpeed = (val: string) => {
    const num = parseFloat(val);
    if (num > 2 || num < 0.5 || Number.isNaN(num)) {
      message.warning('请调整语速在0.5-2.0范围内。');
      return;
    }
    if (num != currSubtitle.voiceSpeed) {
      currSubtitle.setVoiceSpeed(num);
      debounceSave();
    }
  };

  const handleChangeRole = (roleId: any, record: SubtitleData) => {
    projectData.setRoleId(record.sectionId, roleId);
  };

  const handleChangeEmotion = (e: any, record: SubtitleData) => {
    projectData.setEmotionValue(record.sectionId, e);
  };

  useClickAway((e) => {
    if (e.defaultPrevented) return;
    if ((e.target as any).className == 'ant-popover-inner-content') return;
    cancelSelect();
  }, tableRef.current);

  const colgroup = (isHead = false) => {
    const visible = isHead || !scrollVisible;
    return (
      <colgroup>
        <col style={{ width: 40 }} />
        <col style={{ width: 40 }} />
        <col style={{ width: 120 }} />
        <col style={{ width: 120 }} />
        <col style={{ width: 110 }} />
        <col style={{ width: 60 }} />
        <col />
        <col style={{ width: 50 }} />
        <col style={{ width: 50 }} />
        {visible && <col style={{ width: 10 }} />}
      </colgroup>
    );
  };

  return (
    <div className={s.container}>
      <div ref={tableRef} className={s.table}>
        <div
          ref={headerRef}
          className={s.thead}
          onScroll={() => {
            if (bodyRef.current && headerRef.current) bodyRef.current.scrollLeft = headerRef.current.scrollLeft;
          }}>
          <table>
            {colgroup(true)}
            <thead onClick={cancelSelect}>
              <tr>
                <th>预览</th>
                <th>句段</th>
                <th>角色</th>
                <th>情感</th>
                <th>时间轴</th>
                <th>时长</th>
                <th>中文/英文</th>
                <th>字符数</th>
                <th>读速</th>
                <th></th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          ref={bodyRef}
          className={s.tbody}
          onScroll={() => {
            if (bodyRef.current && headerRef.current) headerRef.current.scrollLeft = bodyRef.current.scrollLeft;
            setEditTimeline(false);
          }}>
          <table>
            {colgroup()}
            <tbody ref={bodyContentRef}>
              {props.data.map((record) => (
                <tr
                  className={selectIds.includes(record.sectionId) ? s.highlight : ''}
                  key={record.sectionId}
                  onClick={(e: any) => handleSelect(e, record)}>
                  <td>
                    <Tooltip placement='bottom' title={'预览'}>
                      <div className={s.iconWrap} onClick={(e: any) => handlePreview(e, record)}>
                        <span className={s.previewIcon} />
                      </div>
                    </Tooltip>
                  </td>
                  <td>{record.order + 1}</td>
                  <td>
                    {projectData.merging ? (
                      <span>{projectData.roles.find((r) => r.id == record.roleId)?.alias}</span>
                    ) : (
                      <Select
                        className={s.select}
                        defaultValue={record.roleId}
                        onChange={(e: any) => handleChangeRole(e, record)}
                        options={projectData.roles.map((role) => {
                          return { value: role.id, label: role.alias };
                        })}
                      />
                    )}
                  </td>
                  <td>
                    {record.emotionSupported ? (projectData.merging ? (
                      <span>{record.emotionLabel}</span>
                    ) : (
                      <Select
                        className={s.select}
                        defaultValue={record.emotionLabel}
                        onChange={(e: any) => handleChangeEmotion(e, record)}
                        options={record.emotions}
                      />
                    )): <span>-</span>}
                  </td>
                  <td>
                    <div className={s.flatText}>
                      {editTimeline && selectIds.length > 0 && selectIds[0] == record.sectionId ? (
                        <Popover
                          content={
                            <span className={s.sliderContianer} onClick={(e) => e.preventDefault()}>
                              <Slider
                                className={s.slider}
                                range={{ draggableTrack: true }}
                                value={[toSeconds(start), toSeconds(end)]}
                                max={currSubtitle.timelineRange[1]}
                                min={currSubtitle.timelineRange[0]}
                                step={0.01}
                                onChange={(v: any) => hanldeSliderChange(v)}
                              />
                            </span>
                          }
                          title=''
                          placement='right'
                          trigger='click'
                          open={editTimeline}>
                          <>
                            <Input
                              className={s.textArea}
                              value={start}
                              onChange={(e) => handleTimelineChange(e, record, true)}
                              onClick={(e) => e.preventDefault()}></Input>
                            <Input
                              className={s.textArea}
                              value={end}
                              onChange={(e) => handleTimelineChange(e, record, false)}
                              onClick={(e) => e.preventDefault()}></Input>
                          </>
                        </Popover>
                      ) : (
                        <div className={s.flatText} onClick={(e: any) => handleEditTimeline(e, record)}>
                          <span>
                            {record.timelineCursor.startAt}
                          </span>
                          <span>{record.timelineCursor.endAt}</span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <span>{record.timelineCursor.duration}</span>
                  </td>
                  <td>
                    <div className={s.subtitleText}>
                      {editChs && selectIds.length > 0 && selectIds[0] == record.sectionId ? (
                        <TextArea
                          ref={chsRef}
                          className={s.textArea}
                          value={record.sourceLangText}
                          onChange={(e) => handleSrcTextChange(e, record)}
                          onClick={(e) => handleChsClick(e)}
                          autoSize></TextArea>
                      ) : (
                        <span className={s.text} onClick={(e: any) => handleEditChs(e, record)}>
                          {record.sourceLangText}
                        </span>
                      )}
                      {editEng && selectIds.length > 0 && selectIds[0] == record.sectionId ? (
                        <TextArea
                          ref={engRef}
                          className={s.textArea}
                          value={record.targetLangText}
                          onChange={(e) => handleTargetTextChange(e, record)}
                          onClick={(e) => e.preventDefault()}
                          autoSize></TextArea>
                      ) : (
                        <span className={s.text} onClick={(e: any) => handleEditEng(e, record)}>
                          {record.targetLangText}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className={s.flatText}>
                      <span>{record.sourceLangText.length}</span>
                    </div>
                  </td>
                  <td>
                    {editVoiceSpeed && selectIds.length > 0 && selectIds[0] == record.sectionId ? (
                      <Input
                        className={s.voiceSpeedInput}
                        ref={voiceSpeedRef}
                        value={voiceSpeed}
                        onChange={(e) => handleVoiceSpeedChange(e, record)}
                        onClick={(e) => e.preventDefault()}></Input>
                    ) : (
                      <span onClick={(e: any) => handleEditVoiceSpeed(e, record)}>{record.voiceSpeed}</span>
                    )}
                  </td>
                  {!scrollVisible && <td />}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default React.memo(SubtitleTable);
