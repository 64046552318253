import React, { useEffect, useState } from 'react';
import { Pagination, Table, message } from 'antd';
import { RouteComponent } from '@/routes/RootRouter';
import s from './style.module.css';
import MainLayout from '@/layout';
import cn from 'classnames'
import { ColumnsType } from 'antd/lib/table';
import { fetchRechargePackages, fetchRechargeRecords, fetchRechargeText } from '@/axios/recharge';
import { usePagination } from 'ahooks';

const toDate = (time: number) => new Date(time).toLocaleString();

interface RechargeRecord {
  key: React.Key;
  product: string;
  count: string;
  value: string;
  expiration: string;
  createTime: string;
}

const RechargePage: React.FC<RouteComponent> = () => {
  const [activeIdx, setActiveIdx] = useState(0)
  const [timeStamp, setTimeStamp] = useState(Date.now());
  const [rechargeText, setRechargeText] = useState("");

  const [packages, setPackages] = useState<any[]>([]);

  useEffect(() => {
    fetchRechargeText().then(res => {
      setRechargeText(res.data.text);
    });
    fetchRechargePackages({
      pageNo: 1,
      pageSize: 100,
      start_time: "",
      end_time: "",
    }).then(res => {
      console.log(res)
      if (res.code == 0) setPackages(res.data.list?.map((p: any) => {
        return {
          title: p.recharge_packet_name,
          subTitle: `（有效期${p.validity_period}个月）`,
          price: p.amount,
        };
      }));
    });
  }, [])

  const handlePackageClick = (idx: any) => {
    setActiveIdx(idx);
    message.info("请联系管理员进行充值。");
  }
  
  const getRechargeRecords = (params: {
    current: number;
    pageSize: number;
  }): Promise<{ total: number; list: any[] }> => {
    return new Promise(async (resolve) => {
      const result = await fetchRechargeRecords({
        pageNo: params.current,
        pageSize: params.pageSize,
        start_time: "",
        end_time: "",
      });
      resolve(result.data);
    });
  };

  const { data, loading, pagination } = usePagination(getRechargeRecords, {
    refreshDeps: [timeStamp]
  });

  const rechargeColumns: ColumnsType<RechargeRecord> = [
    {
      title: '时间',
      dataIndex: 'createTime',
    },
    {
      title: '金额',
      dataIndex: 'value',
    },
    {
      title: '包名',
      dataIndex: 'product',
    },
    {
      title: '数量',
      dataIndex: 'count',
    },
    {
      title: '有效期',
      dataIndex: 'expiration',
    },
  ];

  return (
    <MainLayout>
      <div className={s.container}>
        <div className={s.titleBar}>
          购买服务
          <div className={s.intro}>
            <div dangerouslySetInnerHTML={{ __html: rechargeText }} />
          </div>
        </div>
        <div className={s.introList}>
          {
            packages.map((x, idx) => (
              <div className={cn(s.introBlock, activeIdx == idx ? s.activeBlock : "")} key={idx} onClick={() => handlePackageClick(idx)}>
                <div className={cn(s.blockTitle, activeIdx == idx ? s.activeTitle : "")}>{x.title}</div>
                <div className={cn(s.blockSubTitle, activeIdx == idx ? s.activeSubTitle : "")}>{x.subTitle}</div>
                <div className={cn(s.blockPriceLine, activeIdx == idx ? s.activePriceLine : "")}>
                  <div className={s.priceSymbol}>¥</div>
                  <div className={s.priceText}>{x.price}</div>
                </div>
              </div>
            ))
          }
        </div>
        <div className={s.resultPanel}>
          充值记录
          <Table
            className={s.table}
            columns={rechargeColumns}
            dataSource={data?.list?.map(record => {
              return {
                key: record.id.toString(),
                value: record.recharge_amount,
                product: record.recharge_packet_name,
                count: record.recharge_num,
                expiration: record.period,
                createTime: toDate(record.create_time)
              } as RechargeRecord;
            })}
            loading={loading}
            pagination={false}
          />
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={data?.total}
            onChange={pagination.onChange}
            onShowSizeChange={pagination.onChange}
            showSizeChanger
            style={{ marginTop: 16, textAlign: 'right' }}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default RechargePage;
