import DownloaderWorker, { Message, Task, TaskResult } from '@/workers/downloader/downloader.worker';
import { Function } from '@stitches/react/types/util';
import Dexie, { Table } from 'dexie';

export enum MaterialType {
  Audio = 'AUDIO',
  Video = 'VIDEO',
}

export interface SectionMaterial {
  id: string;
  ext?: string;
  name: string;
  filename?: string;
  url?: string;
  blob?: ArrayBuffer;
  size?: number;
  anchor?: boolean;
}

export interface Material {
  id: string;
  sectionId?: string;
  type: MaterialType;
  ext?: string;
  name: string;
  filename?: string;
  url?: string;
  blob?: ArrayBuffer;
  size?: number;
  anchor?: boolean;
}

class MaterialManager extends Dexie {
  private static DATABASE = 'material-store';

  protected materials!: Table<Material>;
  private downloader: Worker;
  private cachedMaterials: Map<string, Material> = new Map<string, Material>();
  private cachedCallbacks: Map<string, Function> = new Map<string, Function>();

  constructor() {
    super(MaterialManager.DATABASE);
    this.version(1).stores({
      materials: '&id, name, filename, url',
    });
    this.downloader = new DownloaderWorker();
    this.downloader.onmessage = this.onDownloaderWorkerMessage.bind(this);
    this.downloader.postMessage({
      type: 'run',
    });
  }

  private async onDownloaderWorkerMessage(event: MessageEvent<Message>) {
    switch (event.data.type) {
      case 'done':
        const result = event.data.payload as TaskResult;
        const material = this.cachedMaterials.get(result.id);
        if (material) {
          material.blob = result.blob;
          material.size = result.size;
          if (material.anchor) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            material.url = material.url.substring(0, material.url.lastIndexOf('?'));
            material.name = result.filename.substring(0, result.filename.lastIndexOf('.'));
            material.ext = result.filename.substring(result.filename.lastIndexOf('.'));
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            material.name = material.url.substring(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              material.url.lastIndexOf('/') + 1,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              material.url.lastIndexOf('.'),
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            material.filename = material.url.substring(material.url.lastIndexOf('/') + 1);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            material.ext = material.filename.substring(material.filename.lastIndexOf('.') + 1);
          }
          await this.add(material);
          this.cachedMaterials.delete(result.id);
          const cb = this.cachedCallbacks.get(result.id);
          if (cb) {
            cb(material);
            this.cachedCallbacks.delete(result.id);
          }
        }
        break;
      default:
        return;
    }
  }

  public submit(
    trackId: string,
    segmentId: string,
    material: SectionMaterial,
    priority: number,
    callback?: Function,
  ): void {
    // this.validateAddRequest(material);

    if (material.url) {
      const request: Task = {
        key: trackId,
        id: material.id,
        segmentKey: segmentId,
        downloadUrl: material.url,
        priority: priority,
      };

      // this.cachedMaterials.set(segmentId, material);
      // this.downloader.postMessage({
      //   type: 'add',
      //   payload: request,
      // });
      // if (callback) {
      //   this.cachedCallbacks.set(segmentId, callback);
      // }
    }
  }

  public download(id: string, material: Material, priority: number, callback?: Function): void {
    // this.validateAddRequest(material);
    if (material.url) {
      const request: Task = {
        key: id,
        id,
        downloadUrl: material.url,
        priority: priority,
      };

      this.cachedMaterials.set(id, material);
      this.downloader.postMessage({
        type: 'add',
        payload: request,
      });
      if (callback) {
        this.cachedCallbacks.set(id, callback);
      }
    }
  }

  public getIdByType(projectId: string, type: string) {
    return `${projectId}_${type}`;
  }

  public getIdByVersion(version: number, sectionId: string) {
    return `${version}_${sectionId}`;
  }

  public async getReousrceFromCache(id: string, sectionId: string, type: MaterialType, url: string, cb: Function) {
    let material = await this.getById(id);
    if (material) {
      cb(material);
    } else {
      material = { id, type: MaterialType.Audio, sectionId, url } as Material;
      this.download(id, material, 1, cb);
    }
  }

  public async overwriteResource(id: string, sectionId: string, type: MaterialType, url: string, cb: Function) {
    const material = {
      id,
      type: MaterialType.Audio,
      sectionId,
      url,
    } as Material;
    this.download(id, material, 1, cb);
  }

  public async getById(materialId: string): Promise<Material | undefined> {
    return this.materials.get(materialId);
  }

  private validateAddRequest(material: Material) {
    if (!material.id && !material.url) {
      throw new Error('添加素材请至少指明素材编号或地址');
    }

    if (!material.type) {
      throw new Error('请指明要添加的素材的类型');
    }

    switch (material.type) {
      case MaterialType.Video:
      case MaterialType.Audio:
        break;
      default:
        throw new Error('要添加的素材为不支持的类型');
    }

    if (!material.name) {
      throw new Error('素材名称不可以为空');
    }
  }

  public async add(material: Material) {
    try {
      this.validateAddRequest(material);
    } catch (e) {
      return Promise.reject(e);
    }

    // 已经存在，不再添加
    const entity = await this.getById(material.id);
    if (entity) {
      return Promise.resolve(true);
    }

    await this.materials.add(material);
    return Promise.resolve();
  }
}

export const materialManager = new MaterialManager();
