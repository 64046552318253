import { useEffect, useRef, useState } from 'react';
import s from './style.module.css';
import { Button, message } from 'antd';
import { observer } from 'mobx-react-lite';
import cn from "classnames"
import { getQAResult } from '@/axios/workbench';
import { projectData } from '@/stores/data/project-data';
import { chatMessages } from '@/stores/data/chat-messages';
import ChatMessage from './chat-message';

const Chat: React.FC<any> = observer((props: {}) => {
  const [question, setQuestion] = useState("");
  const [lastQuestion, setLastQuestion] = useState("");
  const messagesEnd = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages.data.length])
 
  const scrollToBottom = () => {
    if (messagesEnd.current) {
      (messagesEnd.current as any).scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleInput = (e: any) => {
    const text = e.target.value;
    chatMessages.userInput(text);
    setQuestion(e.target.value);
  }

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSend();
    }
  }

  const handleSend = async () => {
    if (question.trim().length < 1) return;
    const text = question;
    setLastQuestion(text);
    setQuestion("");
    chatMessages.sendMessage(projectData.id, text);
  }

  const handleResend = async () => {
    if (lastQuestion.length > 0) {
      chatMessages.sendMessage(projectData.id, lastQuestion);
    }
  }

  const handleClear = async() => {
    chatMessages.clear();
  }

  return (
    <div className={s.container}>
      <div className={s.chatBody}>
        <Button className={cn(s.resendBtn, s.actionBtn)} onClick={handleResend}>
          重新回答
        </Button>
        <Button className={cn(s.clearBtn, s.actionBtn)} onClick={handleClear}>
          清空聊天
        </Button>
        {chatMessages.data.map(m => <ChatMessage key={m.sessionId} isUser={m.isUser} isLoading={false} text={m.text} />)}
        <div style={{ float:"left", clear: "both" }} ref={messagesEnd}>
        </div>        
      </div>
      <div className={s.chatActions}></div>
      <div className={s.chatInput}>
        <textarea className={s.mainInput} placeholder={"请提出一个问题与视频相关"} value={question} onChange={handleInput} onKeyDown={handleKeyDown} />
        <Button className={cn(s.sendBtn, s.actionBtn)} onClick={handleSend}>
          发送
        </Button>
      </div>
    </div>
  );
});

export default Chat;
