import service, { ApiResponse, getToken } from '@/axios/service';

export interface FetchTeamFilesRequest {
  pageNo: number;
  pageSize: number;
  file_name: string;
  start_time: string;
  end_time: string;
}

export interface TeamFileResponse {
  list: TeamFile[];
  total: number;
}

export interface TeamFile {
  id: number;
  duration: number;
  project_id: string;
  team_id: string;
  file_name: string;
  create_time: string;
}

export const fetchTeamFiles = (request: FetchTeamFilesRequest): Promise<ApiResponse<TeamFileResponse>> => {
  return service.get<ApiResponse<TeamFileResponse>>(`/apis/app.aivt.digitalnotee.api/team-files/v1alpha1`, {
    params: request
  });
};

export const deleteTeamFile = (id: string): Promise<ApiResponse<any>> => {
  return service.delete<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/team-files/v1alpha1/${id}`);
};
