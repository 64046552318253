import { IRequest, IRequestHandler } from '@/stores/mediator-system/request';
import { INotification, INotificationHandler } from '@/stores/mediator-system/notification';
import { registry } from '@/stores/mediator-system/registry';
import { dispatcher } from '@/stores/mediator-system/dispatcher';

export interface IMediator {
  send<T>(request: IRequest<T>): Promise<T>;

  publish(notification: INotification, name: string): Promise<void[]>;
}

export class Mediator implements IMediator {
  private _registry = registry;
  private _dispatcher = dispatcher;

  async publish(notification: INotification, name: string): Promise<void[]> {
    const handlers = this._dispatcher.retrieveAll(name);

    const results: Promise<void>[] = [];

    for (const instance of handlers) {
      const handler = this._registry.retrieve<INotificationHandler<INotification>>(instance.handlerName);
      switch (typeof handler) {
        case 'function':
          results.push(handler(notification));
          break;
        case 'object':
          results.push(handler.handle(notification));
          break;
        default:
          break;
      }
    }

    return Promise.all(results);
  }

  async send<T>(request: IRequest<T>): Promise<T> {
    const name = request.constructor.name;
    const handler = this._registry.retrieve<IRequestHandler<IRequest<T>, T>>(name);
    switch (typeof handler) {
      case 'function':
        return handler(request);
      case 'object':
        return handler.handle(request);
      default:
        return Promise.reject();
    }
  }
}

export const mediator = new Mediator();
