import s from './style.module.css';
import { Input, Select, Space, Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RoleData } from '@/stores/model/project';
import { useEffect, useRef, useState } from 'react';
import { SpeakerInfo, getAvailSpeakers, getTrialVoice } from '@/axios/project';
import { projectData } from '@/stores/data/project-data';
import { useDebounceFn } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { PlusOutlined } from '@ant-design/icons';
import cn from "classnames"

const RoleTable: React.FC<any> = observer(() => {
  const [roles, setRoles] = useState<SpeakerInfo[]>([]);
  const [scrollVisible, setScrollVisible] = useState(false);

  const tableRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const bodyContentRef = useRef<any>(null);

  useEffect(() => {
    const observer = new ResizeObserver(_ => {
      setScrollVisible(!(bodyRef.current && bodyRef.current.scrollHeight <= bodyRef.current.clientHeight))
    });
    if (bodyRef.current) {
      observer.observe(bodyRef.current);
    }
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    setRoles(projectData.voices || []);
  }, [projectData.voices])

  const handleRoleChange = (val: string, roleId: string) => {
    projectData.setRoleVoice(roleId, val);
    message.success("更新成功!");
  }
  
  const handleAddRole = async (e: any) => {
    projectData.addRole();
    message.success("添加成功!");
  };


  const handlePreview = async (e: any, voiceId: string) => {
    const res = await getTrialVoice(voiceId);
    if (res.code == 0) {
      const audio = new Audio(res.data);
      audio.crossOrigin = "anonymous";
      audio.addEventListener("loadedmetadata", () => {
        audio.play();

      });
      audio.load();
    }
  }

  const { run: debounceSave } = useDebounceFn(
    () => {
      projectData.save();
      message.success("更新成功!");
    },
    {
      wait: 1000,
    },
  );

  const handleAliasChange = (e: any, roleId: string) => {
    projectData.setRoleAlias(roleId, e.target.value);
    console.log(projectData.roles[0].alias)
    debounceSave();
  }

  const columns: ColumnsType<RoleData> = [
    {
      title: '角色',
      dataIndex: 'id',
    },
    {
      title: '角色名称',
      render: (_, record) => (
        <Input className={s.input} value={record.alias} maxLength={30} placeholder={"请输入角色名称"} onChange={(e: any) => handleAliasChange(e, record.id)}></Input>
      ),
    },
    {
      title: '配音类型',
      dataIndex: 'voice_alias',
      render: (_, record) => (
        <Select
          className={s.select}
          defaultValue={record.voiceId}
          onChange={val => handleRoleChange(val, record.id)}
          options={roles.map((role: SpeakerInfo) => {
            return { value: role.speakerCode, label: `${role.label}-${role.language}-${role.gender}` };
          })}
        />
      ),
    },
  ];

  const colgroup = (isHead = false) => {
    const visible = isHead || !scrollVisible;
    return (
    <colgroup>
      <col style={{width: 75 }} />
      <col style={{width: 90 }} />
      <col />
      <col style={{width: 24 }}/>      
      {visible && <col style={{width: 10 }} />}
    </colgroup>
  )}

  return (
    <div className={s.container}>
      <div ref={tableRef} className={s.table}>
        <div
          ref={headerRef}
          className={s.thead}
          onScroll={() => {
            if (bodyRef.current && headerRef.current) bodyRef.current.scrollLeft = headerRef.current.scrollLeft;
          }}>
          <table>
            {colgroup(true)}
            <thead>
              <tr>
                <th>角色</th>
                <th>角色名称</th>
                <th>配音类型</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          ref={bodyRef}
          className={s.tbody}
          onScroll={() => {
            if (bodyRef.current && headerRef.current) headerRef.current.scrollLeft = bodyRef.current.scrollLeft;
          }}>
          <table>
            {colgroup()}
            <tbody ref={bodyContentRef}>
              {projectData.roles.map(role => (
                <tr key={role.id}>
                  <td>{role.id}</td>
                  <td><Input className={s.input} value={role.alias} placeholder={"请输入角色名称"} onChange={(e: any) => handleAliasChange(e, role.id)}></Input></td>
                  <td>
                    <Select
                      className={s.select}
                      defaultValue={role.voiceId}
                      onChange={val => handleRoleChange(val, role.id)}
                      options={roles.map((role: SpeakerInfo) => {
                        return { value: role.speakerCode, label: `${role.label}(${role.emotionSupported ? "支持情感" : "不支持情感"})-${role.language}-${role.gender}` };
                      })}
                    />
                  </td>
                  <td>
                    <div className={s.iconWrap} onClick={(e: any) => handlePreview(e, role.voiceId)}>
                      <div className={s.voiceIcon}></div>
                    </div>
                  </td>
                  {!scrollVisible && <td />}
                </tr>))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={cn(s.addIcon, s.iconWrap)} onClick={(e: any) => handleAddRole(e)}>
        <PlusOutlined className={s.icon} />
      </div>
    </div>
  );
});

export default RoleTable;
