import { makeAutoObservable } from 'mobx';
import { SubtitleData } from '../model/project';
import { toSeconds } from '@/utils/time';
import { projectData } from './project-data';
import { directData } from './direct-data';

export enum ShowType {
  SourrcOnly = 0,
  TragetOnly = 1,
  SourceAndTarget = 2
}

class CurrentSubtitle {
  id = '';
  startAt = 0;
  endAt = 0;
  sourceLangText = "";
  targetLangText = "";
  voiceSpeed = 0.5;

  showSubttileMode: ShowType = ShowType.SourceAndTarget;
  splitCursor = 0;
  timelineRange = [0, 0];

  constructor() {
    makeAutoObservable(this);
  }

  setShowType(type: ShowType) {
    this.showSubttileMode = type;
  }

  set(subtitle?: SubtitleData) {
    if (subtitle) {
      this.id = subtitle.sectionId;
      this.startAt = toSeconds(subtitle.timelineCursor.startAt);
      this.endAt = toSeconds(subtitle.timelineCursor.endAt);
      this.sourceLangText = subtitle.sourceLangText;
      this.targetLangText = subtitle.targetLangText;
      this.voiceSpeed = subtitle.voiceSpeed;
      this.timelineRange = projectData.getTimelineRange(this.id);
    } else {
      this.id = "";
      this.startAt = 0;
      this.endAt = 0;
      this.sourceLangText = "";
      this.targetLangText = "";
      this.voiceSpeed = 0.5;
    }
  }

  setSourcetLangText(text: string) {
    this.sourceLangText = text;
    projectData.setSourcetLangText(this.id, text);
  }

  setTargetLangText(text: string) {
    this.targetLangText = text;
    projectData.setTargetLangText(this.id, text);
  }

  setVoiceSpeed(voiceSpeed: number) {
    this.voiceSpeed = voiceSpeed;
    projectData.setVoiceSpeed(this.id, voiceSpeed);
  }

  setTimelineCursorStart(start: string) {
    if(!projectData.setTimelineStart(this.id, start)) {
      return;
    }
    this.startAt = toSeconds(start);
    directData.setCurrent(this.startAt);
  }

  setTimelineCursorEnd(end: string) {
    if(!projectData.setTimelineEnd(this.id, end)) {
      return;
    }
    this.endAt = toSeconds(end);
  }

  setSplitCursor(cursor: number) {
    this.splitCursor = cursor;
  }
}

const currSubtitle = new CurrentSubtitle();

export default currSubtitle;
