import React, { useState, useEffect } from 'react';
import { renderRoutes, RouteComponent } from '@/routes/RootRouter';
import s from './style.module.css';
import MainLayout from '@/layout';
import Workitem from './workitem';
import ImportMaterial from './import-material';
import { Col, Row } from 'antd';
import { ProjectListResult, ProjectMeta, fetchProjects } from '@/axios/project';
import { ApiResponse } from '@/axios/service';
import { useRafState, useRafInterval  } from 'ahooks';
import { projectData } from '@/stores/data/project-data';
import { observer } from 'mobx-react-lite';

const WorkbenchPage: React.FC<RouteComponent> = observer(({ routes }) => {
  const [projects, setProjects] = useRafState<ProjectMeta[]>([]);

  useEffect(() => {
    projectData.loading = false;
    refreshProjects();
  }, [])

  useRafInterval(() => {
    refreshProjects();
  }, 5 * 1000);

  const refreshProjects = () => {
    fetchProjects().then(({ data }: ApiResponse<ProjectMeta[]>) => {
      data && setProjects(data);
    })
  }

  const renderList = () => {
    {
      return projects.length > 0 && projects.map((item: ProjectMeta, i: number) => (
        <Col className={s.item} key={i}>
          <Workitem {...item} />
        </Col>
      ))
    }
  }

  return (
    <MainLayout>
      <div className={s.container}>
        <div className={s.listwrap}>
          <Row className={s.list} gutter={[20, 20]}>
            <Col className={s.firstitem}>
              <div className={s.importArea}>
                  <div className={s.dropzone}>
                    <ImportMaterial onSubmit={refreshProjects} />
                  </div>
              </div>
            </Col>
            {renderList()}
          </Row>
        </div>
      </div>
    </MainLayout>
  );
});

export default WorkbenchPage;
