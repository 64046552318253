import service, { ApiResponse, getToken } from '@/axios/service';
import project from '@/pages/project';
import userStore from '@/stores/user';
import { compress } from '@/utils/gzip';

export interface ProjectPayload {
  type: string; // 消息类型
  project_id: string; // 工程ID
  team_id: string; // 团队ID
  payload: ProjectSnapshot; // snapshot
}

export interface ProjectSnapshot {
  id: string; // 工程ID
  name: string; // 工程名
  version: number; // 版本
  source_lang: string; // 源语言
  target_lang: string; // 目标语言
  video_obj_link: string; // 视频源
  audio_obj_link: string; // 音频源
  bg_obj_link: string; // 背景声
  roles: Role[]; // 角色配置
  subtitles: Subtitle[]; // 字幕配置
  subtitleObjects: string[][]; // 比对区文字
  subtitle_obj_link: string;
  sensitive_word_settings: SensitiveWordData[];
  knowledge_base_id: string;
  generated_audio_obj_link: string; // 合成视频链接
}

export interface SensitiveWordData {
  sensitive_word: string;
  replace: string;
}

export interface Role {
  role_id: string; // 角色编号
  alias: string; // 角色别名
  voice_id: string; // 声音编号
  voice_alias: string; // 声音别名
}

export interface Subtitle {
  section_id: string; // 字幕编号（唯一）
  role_id: string; // 对应角色编号
  emotion_value: string; // 情感标签
  original_audio: string; // 原始音频地址
  timeline_cursor: TimelineCursor; // 时间范围 
  order: number; // 排序
  source_lang_text: string; // 源语言文本
  target_lang_text: string; // 目标语言文本
  character_count: number; // 字符数
  voice_speed: number; // 读速
  gen_audio: string; // 生成的目标音频
}

export interface TimelineCursor {
  started_at: string; // 开始时间
  ended_at: string; // 结束时间
  duration: number; // 片段长度
}

export interface CreateProjectRequest {
  name: string;
  video_obj_link: string;
  audio_obj_link: string;
  subtitle_obj_link: string;
  duration: number;
  source_lang: string;
  target_lang: string;
}

export interface FetchProjectRequest {
  page_no: number;
  page_size: number;
  team_id: number;
  name_like: string;
}

export interface TranslateRequest {
  text: string;
  from: string;
  to: string;
}

export interface PreviewRequest {
  team_id: number;
  project_id: string;
  section_id: string;
}

export interface ProjectListResult {
  list: ProjectMeta[];
  total: number;
}

export interface ProjectMeta {
  id: string;
  name: string;
  status: string;
  team_id : string;
  video_obj_link: string;
  create_time: number;
  update_time: number;
  initialize_message: string
}

export interface PreviewResponse {
  projectId: number;
  teamId: number;
  sectionId: string;
  objectPath: string;
}

export interface EmotionInfo {
  label: string;
  value: string;
}

export interface SpeakerInfo {
  speakerCode: string;
  label: string;
  language: string;
  gender: string;
  volume: number;
  speed: number;
  emotionSupported: boolean;
  emotions: EmotionInfo[];
}

export const createProject = (req: CreateProjectRequest): Promise<ApiResponse<string>> => {
  const team_id = userStore.info?.teamId;
  return service.post<ApiResponse<string>>(`/apis/app.aivt.digitalnotee.api/projects/v1alpha1/${team_id}`, req);
};

export const fetchProjects = (): Promise<ApiResponse<ProjectMeta[]>> => {
  const team_id = userStore.info?.teamId;
  return service.get<ApiResponse<ProjectMeta[]>>(`/apis/app.aivt.digitalnotee.api/projects/v1alpha1/pagination`, {
    params: {
      page_no: 1,
      page_size: 10,
      team_id,
      name_like: ""
    } as FetchProjectRequest
  });
};

export const retryProjects = (project_id: string): Promise<ApiResponse<any>> => {
  const team_id = userStore.info?.teamId;
  return service.post<ApiResponse<ProjectMeta[]>>(`/apis/app.aivt.digitalnotee.api/projects/v1alpha1/${team_id}/${project_id}/retry`, {});
};

export const getLatestSnapshot = (projectId: string): Promise<ApiResponse<ProjectPayload>> => {
  const team_id = userStore.info?.teamId;
  return service.get<ApiResponse<ProjectPayload>>(`/apis/app.aivt.digitalnotee.api/projects/v1alpha1/${team_id}/${projectId}/snapshot/latest`);
}

export const translate = (text: string, from: string, to: string): Promise<ApiResponse<string>> => {
  return service.post<ApiResponse<string>>(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/text/translate`, {
    text,
    from,
    to,
  } as TranslateRequest);
};

export const previewAudio = (project_id:string, section_id: string): Promise<ApiResponse<PreviewResponse>> => {
  return service.post<ApiResponse<PreviewResponse>>(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/audio/generate`, {
    team_id: userStore.info?.teamId,
    project_id,
    section_id
  } as PreviewRequest);
};

export const saveSnapshot = async (project_id: string, data: any): Promise<ApiResponse<string>> => {
  const content: string = JSON.stringify(data);
  const snapshot: string = await compress(content) as string;
  const team_id = userStore.info?.teamId;
  return service.post<ApiResponse<string>>(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/${team_id}/${project_id}/snapshot/save`, {
    snapshot
  });
};

export const genAllAudio = (project_id: string): Promise<ApiResponse<any>> => {
  const team_id = userStore.info?.teamId;
  return service.get<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/${team_id}/${project_id}/audio/gen/all`);
}

export const getGenStatus = (project_id: string): Promise<ApiResponse<any>> => {
  const team_id = userStore.info?.teamId;
  return service.get<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/${team_id}/${project_id}/audio/gen/status`);
}

export const getAll = () => {
  const team_id = userStore.info?.teamId;
  return service.get<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/projects/v1alpha1/${team_id}/status`)
}

export const getAvailSpeakers = (): Promise<ApiResponse<SpeakerInfo[]>> => {
  return service.get<ApiResponse<SpeakerInfo[]>>(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/audio/speakers`);
};

export const getTrialVoice = (voiceId: string): Promise<ApiResponse<any>> => {
  return service.get<ApiResponse<any>>(`/apis/app.aivt.digitalnotee.api/workbench/v1alpha1/audio/trail/${voiceId}`);
}

// TODO
export const getSensitiveWords = (): Promise<ApiResponse<any>> => {
  return Promise.resolve({
    code: 0,
    msg: '',
    data: [
      { keywords: "xxx", replace: "*" },
      { keywords: "yyy", replace: "**" },
    ] as any
  });
}
