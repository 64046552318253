import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';
import { Dropdown, Menu, MenuProps, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import s from './style.module.css';
import directingEngine, { DirectEngine } from '@/stores/directing-engine/direct-engine';
import { playerSize } from '@/stores/data/player-size';
import PlayerTimeLine from '@/components/player-time-line';
import { useDebounceFn } from 'ahooks';
import ReactPlayer from 'react-player';
import SubtitleTrack from './subtitle-track';
import { projectData } from '@/stores/data/project-data';
import currSubtitle, { ShowType } from '@/stores/data/curr-subtitle';
import { directData } from '@/stores/data/direct-data';

const Video: React.FC<any> = observer(({ isFullScreen, onFullScreenChange }) => {
  const { projectId } = useParams<Record<string, any>>();
  const wrapRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<any>(null);
  const play = directingEngine.isPlaying;
  const [playing, setPlaying] = useState(false);

  const [volume, setVolume] = useState(1);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver(_ => {
      const { width: parentWidth, height: parentHeight } = (wrapRef.current as any)?.getBoundingClientRect();

      playerSize.containerHeight = parentHeight;
      playerSize.containerWidth = parentWidth;

      setWidth(parentWidth)
      setHeight(parentHeight)
    });
    if (wrapRef.current) {
      observer.observe(wrapRef.current);
    }
    return () => observer.disconnect();
  }, [projectId, isFullScreen]);

  useEffect(() => {
    if (!directingEngine.isPlaying) {
      setPlaying(false)
      videoRef.current && videoRef.current.seekTo(directData.getCurrent(), "seconds");
    } else if (directingEngine.isPreviewing) {
      setVolume(0);
      setPlaying(true);
    }
  }, [directData.current, directingEngine.isPlaying])

  const { run: didPlay } = useDebounceFn(
    (e) => {
      console.log(e)
      e.preventDefault();
      if (directData.current >= directData.duration) {
        return;
      }
      if (playing) {
        directingEngine.play();
      }
      setPlaying(!playing)
    },
    {
      wait: 80,
    },
  );

  const handleHotKeyUp = async (e: React.KeyboardEvent) => {
    if (!e.ctrlKey && !e.altKey && !e.metaKey && !e.shiftKey) {
      if (e.code === 'Space') {
        // directingEngine.play();
      } else if (e.code === 'Backquote') {
        onFullScreenChange(!isFullScreen);
        playerSize.isFullScreen = !isFullScreen;
      }
    }
  };

  /**
   * use this callback to track real start
   **/
  const handlePlay = () => {
    if (!directingEngine.isPreviewing) {
      setVolume(1);
      directingEngine.play();
    }
    console.log('playing')
  }

  const handleReady = () => {
    if (videoRef.current) {
      const duration = videoRef.current.getDuration();
      directData.setDuration(duration);
    }
  }

  const items: MenuProps['items'] = [
    {
      label: "中文",
      key: ShowType.SourrcOnly,
    },
    {
      label: "英文",
      key: ShowType.TragetOnly,
    },
    {
      label: "中文和英文",
      key: ShowType.SourceAndTarget,
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    currSubtitle.setShowType(key as any);
  };

  return (
    <div
      className={cn(s.videoWrap, isFullScreen && s.videoWrapFullScreen)}
      ref={wrapRef as any}
      tabIndex={-1}
      onKeyUp={handleHotKeyUp}
      onClick={(e: any) => e.preventDefault()}
    >
      <ReactPlayer
        className={s.videoPlayer}
        ref={videoRef as any}
        width={width}
        height={height}
        playIcon={<div className={s.playCircleIcon} /> }
        playsinline={true}
        playing={playing}
        onPlay={handlePlay}
        onReady={handleReady}
        volume={volume}
        light={false}
        config={{
          file: {
            attributes: {
              crossOrigin: "true",
              controlsList: 'nodownload'
            }
          }
        }}
        url={projectData.video_obj_link}
      />
      <SubtitleTrack />
      <div className={s.video_controlWrap}>
        {isFullScreen && (
          <PlayerTimeLine
            current={directData.getCurrent()}
            total={directData.duration}
            isPause={!playing}
            onChange={(val) => directData.setCurrent(val)}
          />
        )}
        <div className={s.video_control}>
          <div className={s.control_btn} onClick={e => {e.preventDefault(); didPlay(e);}}>
            {!playing ? <div className={s.playIcon} /> : <div className={s.pauseIcon} />}
          </div>
          <div className={s.voiceIcon}></div>
          <div className={s.time}>
            <span className={s.startTime}>{directData.getCurrentHMS()}</span> /{' '}
            <span className={s.totalTime}>{directData.getDurationHMS()}</span>
          </div>
          <div className={s.action_bar}>
            <Tooltip title={isFullScreen ? '取消全屏' : '全屏'} placement='top'>
              <div
                className={isFullScreen ? s.cancelFullScreen : s.fullScreen}
                onClick={() => {
                  onFullScreenChange(!isFullScreen);
                  playerSize.isFullScreen = !isFullScreen;
                }}></div>
            </Tooltip>

            <Tooltip title={'字幕显示'} placement='top'>
              <Dropdown menu={{ items, onClick }} overlayClassName={s.menu} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                <div className={s.subtitleIcon} />
                </a>
              </Dropdown>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(Video);
