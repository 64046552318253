import { getQuota } from '@/axios/recharge';
import { TeamFile, deleteTeamFile, fetchTeamFiles } from '@/axios/team-file';
import MainLayout from '@/layout';
import { RouteComponent } from '@/routes/RootRouter';
import { toHour, toMinute, toSecond } from '@/utils/time';
import { usePagination } from 'ahooks';
import { DatePicker, Pagination, Space, Table, message } from 'antd';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input/Input';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import s from './style.module.css';

interface DataType {
  key: React.Key;
  name: string;
  period: string;
  createTime: string;
}

const TeamFilesPage: React.FC<RouteComponent> = ({ routes }) => {
  const [name, setName] = useState('');
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [timeStamp, setTimeStamp] = useState(Date.now());

  const [quota, setQuota] = useState({
    total: 0,
    used: 0,
    remain_available_seconds: 0,
  });

  useEffect(() => {
    getQuota().then((res) => {
      if (res.code == 0) setQuota(res.data);
    });
  }, []);

  const getTeamFiles = (params: {
    current: number;
    pageSize: number;
  }): Promise<{ total: number; list: TeamFile[] }> => {
    return new Promise(async (resolve) => {
      const result = await fetchTeamFiles({
        pageNo: params.current,
        pageSize: params.pageSize,
        file_name: name,
        start_time: startTime ? startTime.format('yyyy-MM-DD HH:mm:ss') : '',
        end_time: endTime ? endTime.format('yyyy-MM-DD HH:mm:ss') : '', 
      });
      resolve(result.data);
    });
  };

  const { data, loading, pagination } = usePagination(getTeamFiles, {
    refreshDeps: [timeStamp],
  });


  const handleSearch = () => { 
    search();
  };

  const handleDeleteTeamFile = async (id: string) => {
    const res = await deleteTeamFile(id);
    search();
    message.success('账号删除成功!');
  };

  const search = () => {
    setTimeStamp(Date.now());
  };

  const types = [
    { title: '团队总时长', hour: toHour(quota.total), minute: toMinute(quota.total), second: toSecond(quota.total) },
    { title: '团队已使用时长', hour: toHour(quota.used), minute: toMinute(quota.used), second: toSecond(quota.used) },
    {
      title: '团队剩余时长',
      hour: toHour(quota.remain_available_seconds),
      minute: toMinute(quota.remain_available_seconds),
      second: toSecond(quota.remain_available_seconds),
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '视频时长',
      dataIndex: 'period',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          {/* <a onClick={(e: any) => handleDeleteTeamFile(record.key.toString())}>删除</a> */}
        </Space>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
      name: record.name,
    }),
  };

  return (
    <MainLayout>
      <div className={s.container}>
        <div className={s.introList}>
          {types.map((x, idx) => (
            <div className={s.introBlock} key={idx}>
              <div className={s.blockTitle}>{x.title}</div>
              <div className={s.blockTimeLine}>
                <div className={s.timeText}>{x.hour}</div>
                <div className={s.timeUnit}>小时</div>
                {
                  <>
                    <div className={s.timeText}>{x.minute}</div>
                    <div className={s.timeUnit}>分</div>
                  </>
                }
                {
                  <>
                    <div className={s.timeText}>{x.second}</div>
                    <div className={s.timeUnit}>秒</div>
                  </>
                }
              </div>
            </div>
          ))}
        </div>
        <div className={s.actionBar}>
          <span>日期:</span><DatePicker.RangePicker format="YYYY-MM-DD" value={[startTime, endTime]} onChange={(value: any) => {
            setStartTime(value[0]);
            setEndTime(value[1]);
          }} />
          <span>名称:</span><Input className={s.mailInput} value={name} maxLength={80} onChange={(e) => setName(e.target.value)} placeholder="请输入名称" />
          <div className={s.actionArea}>
            <Button className={s.actionBtn} type='primary' onClick={handleSearch}>
              搜索
            </Button>
            {/* <Button className={s.actionBtn} type="primary" danger onClick={() => { }}>
              删除
            </Button> */}
          </div>
        </div>
        <div className={s.resultArea}>
          <Table
            className={s.table}
            // rowSelection={{
            //   type: 'checkbox',
            //   ...rowSelection,
            // }}
            columns={columns}
            dataSource={data?.list?.map((file) => {
              return {
                key: file.id.toString(),
                name: file.file_name,
                period: file.duration.toString() + '秒',
                createTime: new Date(file.create_time).toLocaleDateString(),
              } as DataType;
            })}
            loading={loading}
            pagination={false}
          />
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={data?.total}
            onChange={pagination.onChange}
            onShowSizeChange={pagination.onChange}
            showSizeChanger
            style={{ marginTop: 16, textAlign: 'right' }}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default TeamFilesPage;
