import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { Button, Segmented, Tooltip, message, Popover } from 'antd';
import { RouteComponent } from '@/routes/RootRouter';
import { useHistory } from 'react-router-dom';
import s from './style.module.css';
import MainLayout from '@/layout';
import SubtitleTable from './subtitle-table';
import RoleTable from './role-table';
import Track from './track';
import ReplaceForm from './replace-form';
import cn from "classnames"
import { SegmentedValue } from 'antd/lib/segmented';
import Video from './video';
import { projectData } from '@/stores/data/project-data';
import { observer } from 'mobx-react-lite';
import { WriteSrt } from '@/utils/srt';
import currSubtitle from '@/stores/data/curr-subtitle';
import directingEngine from '@/stores/directing-engine/direct-engine';
import { PlusOutlined } from '@ant-design/icons';
import { directData } from '@/stores/data/direct-data';
import { RESOURCE_TYPE } from '@/axios/storage';
import { uploadFile } from '@/storage';
import SensitiveWords from './sensitive-words';
import Summary from './summary';
import Chat from './chat';
import { useClickAway } from 'ahooks';
import { Prompt } from 'react-router-dom'

const useBeforeUnload = () => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (directingEngine.isPlaying) {
        directingEngine.play();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

const ProjectPage: React.FC<RouteComponent> = observer(({ routes }) => {
  const history = useHistory();
  const { projectId } = useParams<Record<string, any>>();
  const insertRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isBlocking, setIsBlocking] = React.useState(true)

  useEffect(() => {
    if (directingEngine.isPlaying || directingEngine.isPreviewing) directingEngine.play();
    currSubtitle.set();
    directData.setCurrent(0);
    projectData.init(projectId);
  }, [projectId])

  const [tab, setTab] = useState("角色");
  const [mainTab, setMainTab] = useState("字幕翻译");
  const [popOpen, setPopOpen] = useState(false);

  const handleDownloadSrt = async () => {
    const blob = WriteSrt(projectData.subtitles);
    const file = new File([blob], "output.srt");
    const callbacks = {
      onProgress: (e: any) => console.log(e),
      onSuccess: (e: any) => {
        console.log(e);
      }, 
      onError: (e: any) => console.log(e) } as any;
    const url = await uploadFile(file, RESOURCE_TYPE.SUBTITLE_ARTIFACT_UPLOAD, callbacks, projectData.id);    
  }

  const handleGenAudio = async () => {
    projectData.previewAll = false;
    if (directingEngine.isPlaying) {
      directingEngine.play();
    }
    projectData.downloadAudio();
  }

  const tools = [
    // { title: '编辑', name: 'edit', class: s.editIcon },
    // { title: '复制', name: 'copy', class: s.copyIcon },
    { title: '合并', name: 'merge', class: s.linkIcon },
    { title: '拆分', name: 'split', class: s.cutIcon },
    { title: '删除', name: 'delete', class: s.deleteIcon },
    { title: '翻译', name: 'translate', class: s.translateIcon }
  ];

  const handleToolbarAction = async (e: MouseEvent, name: string, params?: any) => {
    e.preventDefault();
    if (currSubtitle.id == "" && name != "merge") {
      message.warning("请先选择字幕后再试。");
      return;
    }
    if (directingEngine.isPlaying) {
      directingEngine.play();
    }
    switch (name) {
      case 'insert':
        projectData.insertSubtitle(params);
        break;
      case 'merge':
        projectData.merging = true;
        // projectData.mergeSubtitle();
        break;
      case 'split':
        if (currSubtitle.splitCursor == 0) {
          message.warning("请先在原始文本中选择拆分位置。");
          return;
        }
        projectData.splitSubtitle(currSubtitle.splitCursor);
        break;
      case 'delete':
        projectData.deleteSubtitle();
        break;
      case 'translate':
        projectData.translate();
        break;
      default:
        console.log(`Action: ${name}`);
    }
  };

  const handleInsert = (e: any, previous: boolean) => {
    handleToolbarAction(e, "insert", previous);
    setPopOpen(false);
  };

  const handlePreviewAll = (e: any) => {
    projectData.previewAll = true;
    projectData.downloadAudio();
  };

  const handleMerge = () => {
    projectData.mergeSubtitle();
  };

  const handleMergeCancel = () => {
    projectData.merging = false;
  };

  useClickAway((e) => {
    setPopOpen(false);
  }, insertRef.current);

  return (
    <MainLayout>
      <Prompt
        when={isBlocking}
        message={(location) => {
          if (directingEngine.isPlaying) {
            console.log("123")
            directingEngine.play();
          }
          return true;
        }}
      />
      <div className={s.container}>
        <div className={s.workArea}>
          <div className={s.leftSection}>
            <div className={s.videoSection}>
              <Video isFullScreen={isFullScreen} onFullScreenChange={setIsFullScreen} />
            </div>
            <div className={s.trackSection}>
              <Track />
            </div>
            <div className={s.charSection}>
              <Segmented className={s.tab} block options={['角色', '全局替换', '敏感词']} value={tab} onChange={(val: SegmentedValue) => setTab(val.toString())} />
              {
                tab == "角色" ? <RoleTable /> : tab == "全局替换" ? <ReplaceForm replace={() => { }} /> : <SensitiveWords replace={() => { }} />
              }
            </div>
          </div>
          <div className={s.mainSection}>
            {/* <div className={s.subtitleSection}>
                <Segmented className={s.tab} block options={['字幕翻译', '总结', '问答']} value={mainTab} onChange={(val: SegmentedValue) => setMainTab(val.toString())} />
            </div> */}
            <div className={s.mainContent}>
            {
              mainTab == "字幕翻译" ? <>
                <div className={s.toolbar} onClick={(e: any) => e.preventDefault()}>
                  <div className={s.text}>字段数：{projectData.subtitles.length > 0 && projectData.subtitles.reduce(function (acc, obj) { return acc + obj.characterCount; }, 0)}</div>
                  { projectData.merging ? <>
                      <div className={s.indicator}>请按住shift选择需要合并的句段，点击确认后完成。</div>
                      <Button className={s.mergeBtn} type='primary' onClick={e => handleMerge()}>确认</Button>
                      <Button className={s.mergeBtn} onClick={e => handleMergeCancel()}>取消</Button>
                    </>: <>
                      <Tooltip key={"preview"} placement="bottom" title={"预览"}>
                        <div className={s.iconWrap}>
                          <span className={cn(s.previewIcon, s.icon)} onClick={(e: any) => handlePreviewAll(e)} />
                        </div>
                      </Tooltip>
                      <Tooltip key={"insert"} placement="bottom" title={"新增"}>
                        <Popover
                          content={<div className={s.popOverContainer}>
                            <a className={s.popOverOption} onClick={e => handleInsert(e, true)}>上方</a>
                            <a className={s.popOverOption} onClick={e => handleInsert(e, false)}>下方</a>
                          </div>}
                          title=""
                          trigger="click"
                          open={popOpen}
                          onOpenChange={e => setPopOpen(true)}
                        >
                          <div ref={insertRef} className={s.iconWrap}>
                            <PlusOutlined className={s.icon} />
                          </div>
                        </Popover>
                      </Tooltip>
                      {tools.map(t => (
                        <Tooltip key={t.name} placement="bottom" title={t.title}>
                          <div className={s.iconWrap} onClick={(e: any) => handleToolbarAction(e, t.name)}>
                            <span className={cn(t.class, s.icon)} />
                          </div>
                        </Tooltip>
                      ))}
                    </>
                  }
                </div>
                <SubtitleTable data={projectData.subtitles} />
              </>: mainTab == "总结" ? <Summary /> : <Chat />
            }
            </div>
          </div>
          <div className={s.rightSection}>
            <div>
              <Button className={s.actionBtn} onClick={handleDownloadSrt}>
                字幕下载
              </Button>
              <Button className={s.actionBtn} onClick={handleGenAudio}>
                音频下载
              </Button>
            </div>
            <div className={s.previewSection}>
              {
                projectData.subtitleObjects?.map((subtitle, index) =>
                (
                  <table key={index}>
                    <tbody>
                      <tr>
                        <td className={s.timeSpan}>{subtitle[0]}</td>
                        <td className={s.contentSpan}>{subtitle[1]}</td>
                      </tr>
                    </tbody>
                  </table>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
});

export default React.memo(ProjectPage);
